import React, { Component } from 'react';
import ConsultationViewModal from '../DoctorOnCall/ConsultationViewModal';
import { withRouter } from 'react-router-dom';

class SingleOnlineConsultationModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showOnlineConsultModal: true,
    };
  }
  
  closeOnlineConsultationView = () => {
    this.setState({
      showOnlineConsultModal: false,
    });
  };

  render() {
    const searchParams = new URLSearchParams(this.props.location.search);
    const requestId = searchParams.get('reqId');
    const userId = searchParams.get('u');
    const isScheduled = searchParams.get('sc');

    return (
      <div>
        <ConsultationViewModal
          modalIsOpen={this.state.showOnlineConsultModal}
          consultationId={requestId}
          closeModal={this.closeOnlineConsultationView}
          consultationType={ isScheduled  == 'true' ? 'scheduled-consults' : 'online-sp'}
          userId={userId}
        />
      </div>
    );
  }
}

export default withRouter(SingleOnlineConsultationModal);
