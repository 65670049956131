import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Login from './components/login/login';
import { connect } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtoolsPanel } from 'react-query/devtools';
import OfflineConsut from './components/offline-consultations/OfflineConsultation';
import SideBar from './components/layout/SideBar';
import styled from 'styled-components';
import LogOut from './components/logout/LogOut';
import DoctorOnCall from './components/DoctorOnCall/DoctorOnCall';
import CustomerSupport from './components/customer-support/CustomerSupport';
import CustomerSupportUser from './components/customer-support/User';
import LabsOrdersHome from './components/lab-orders/LabOrdersHome';
import AhcRequests from './components/lab-orders/AhcRequests';
import LabRxRequests from './components/lab-rx-requests/LabRxRequestsHome';
import LabReimbursements from './components/lab-rx-requests/labsReimbursements';
import LabRxRequestDetails from './components/lab-rx-requests/LabRxRequestDetails';
import LabsReconcileHome from './components/labs-reconcile/LabsReconcileHome';
import LabsUploadPartnerTnx from './components/labs-reconcile/LabsUploadPartnerTnx';
import CreateTranscriptionRequest from './components/lab-rx-requests/LabRxCreator/CreateTranscriptionRequest';
import NewLabOrderDetails from './components/lab-orders/LabOrderDetails';
import ReimbursementClaimsRequests from './components/reimbursement-claims/ReimbursementClaimsRequests';
import ReimburseClaimRequest from './components/reimbursement-claims/ReimburseClaimRequest';
import ConsultationReimburseRequests from './components/reimbursement-claims/ConsultationReimburseRequests';
import AddDoctors from './components/AddDoctors/AddDoctors';
import OnlinePaymentHome from './components/online-payment/OnlinePaymentHome';
import DoctorPoolComponent from './components/AdminActions/DoctorPoolComponent';
import LabsOnboardingNavigator from './components/labs-onboard/LabsOnboardingNavigator';
import PaymentRequests from './components/request-payment/PaymentRequests';
import OpdManagement from './components/opd-management/OpdManagement';
import Products from './components/redemptions/Products';
import Pools from './components/redemptions/Pools';
import SponsorManagementNavigator from './components/sponsor-management/SponsorManagementNavigator';
import RedemptionOpsNavigator from './components/redemption-ops/RedemptionOpsNavigator';
import PharmacyOrdersHome from './components/pharmacy-orders/PharmacyOrdersHome';
import NewPharmacyOrderDetails from './components/pharmacy-orders/PharmacyOrderDetails';
import SponsorsPoolComponent from './components/AdminActions/SponsorsPoolComponent';
import DoctorLiveStats from './components/AdminActions/DoctorLiveStats';
import LabOrderDetailsV2 from './components/lab-orders/LabOrderDetailsV2';
import OnlineConsultationBooking from './components/online-consultation-booking/OnlineConsultationBooking';
// import ExceptionalReimbursement from './components/AdminActions/ExceptionalReimbursement';
import ExceptionalReimbursement from './components/ExceptionalReimbursement/ExceptionalReimbursement';
import PhysioProgramEnrolls from './components/AdminActions/PhysioProgramEnrolls';
import IntimationRequest from './components/intimation-requests/intimationRequest';
import CashlessVaccination from './components/cashless-vaccination/CashlessVaccination';
import Coupons from './components/coupon.js/Coupons';
import AddedDoctorRequestList from './components/AddedDoctorRequestList/AddedDoctorRequestList'
import makeCredentials from './components/makeCredentials/makeCredentials';
import ReimbursementAdmin from '../src/components/AdminActions/Admin';
import AddReimbursementDoctor from '../src/components/AdminActions/AddReimbursementDoctor';
import ReimbursementQcCheck from './components/reimbursement-claims/ReimbursementQcCheck';
import ReimbursementQcRemark from './components/reimbursement-claims/ReimbursementQcRemark';
import manageCredentials from './components/makeCredentials/manageCredentials';
import CashlessDental from './components/cashless-dental/CashlessDental';
import SingleRequestDetail from './components/offline-consultations/SingleRequestDetail';
import SingleRequestDetailWrapper from './components/offline-consultations/SingleRequestDetailWrapper';
import ReavampOfflineConsultations from './components/revamp-offline-ops/ReavampOfflineConsultations';

import ApproveCredentials from './components/makeCredentials/ApproveCredentials';
import AgentActivityTable from './components/AgentActivityCheck/AgentActivityTable';
import SingleOnlineConsultationModal from './components/DoctorOnCall/SingleOnlineConsultationModal';

const OuterComponent = styled.div`
  /* background-color: rgba(136, 82, 204, 0.4); */
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  align-self: stretch;
  justify-content: stretch;
`;

const BodyContainer = styled.div`
  /* background-color: rgba(136, 82, 204, 0.4); */
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: stretch;
  flex: 9;
  width: 80%;
`;
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

class App extends Component {
  render() {
    return (
      <QueryClientProvider client={queryClient}>
        <Router>
          <OuterComponent>
            <ToastContainer
                  position="top-right"
                  autoClose={8000}
                  hideProgressBar
                  newestOnTop
                  closeOnClick
                  rtl={false}
                  pauseOnVisibilityChange
                  draggable
                  pauseOnHover
            />
            {this.props.showSideBar && <SideBar />}
            <BodyContainer>
              <Switch>
                <Route path="/" exact name="Login" component={Login} />
                <Route
                  path="/revamp-offline-consults/:reqId"
                  name="Single Request Detail"
                  component={SingleRequestDetailWrapper}
                />
                <Route
                  path="/revamp-offline-consults/"
                  name="Revamp Offline Consults"
                  component={ReavampOfflineConsultations}
                />
                <Route
                  path="/agent-activity-check/"
                  name="Agent Activity Check"
                  component={AgentActivityTable}
                />
                {/* <Route
                  path="/prebooked-appointments/"
                  name="Prebooked Appointments"
                  component={() => <OfflineConsut prebooked={true} />}
                /> */}
                <Route
                  path="/provider-claims-settlement/"
                  name="Provider Claimed Settelment"
                  component={() => <OfflineConsut providerClaims={true} />}
                />
                <Route
                  path="/consult-reimburse-requests"
                  name="Reimbursement Consultation Request"
                  component={ConsultationReimburseRequests}
                />
                <Route
                  path="/addDoctors"
                  name="add doctors"
                  component={AddDoctors}
                />
                {/* <Route
                  path="/reimburse-claims"
                  name="Consult Reimbursement Requests"
                  component={ReimbursementClaimsRequests}
                />
                <Route
                  path="/reimburse-claim/:claimId"
                  name="Consult Reimbursement Requests"
                  component={ReimburseClaimRequest}
                /> */}
                <Route
                  path="/payment-requests"
                  name="Payment Requests"
                  component={PaymentRequests}
                />

                <Route path="/logout" exact name="Logout" component={LogOut} />
                <Route
                  path="/doc-on-call"
                  name="Doctor on call"
                  // component={DoctorOnCall}
                  component={(props) => (
                    <DoctorOnCall {...props} consultationType={'doc-on-call'} />
                  )}
                />
                <Route
                  path="/offline-sp"
                  name="Offline Sp"
                  // component={DoctorOnCall}
                  component={(props) => (
                    <DoctorOnCall {...props} consultationType={'offline-sp'} />
                  )}
                />
                <Route
                  path="/online-consults"
                  name="Online Consults"
                  component={SingleOnlineConsultationModal}
                />
                <Route
                  path="/specialist-consults"
                  name="Specialist Consults"
                  // component={SpecialistConsults}
                  component={(props) => (
                    <DoctorOnCall {...props} consultationType={'online-sp'} />
                  )}
                />
                <Route
                  path="/scheduled-consults"
                  name="Scheduled Specialist Consults"
                  // component={SpecialistConsults}
                  component={(props) => (
                    <DoctorOnCall
                      {...props}
                      consultationType={'scheduled-consults'}
                    />
                  )}
                />

                <Route
                  path="/customer-support"
                  name="Customer Support"
                  component={CustomerSupport}
                />
                <Route
                  path="/customer-support-user"
                  name="Customer Support User"
                  component={CustomerSupportUser}
                />
                <Route
                  path="/labs-orders"
                  name="Labs Orders"
                  component={LabsOrdersHome}
                />
                <Route
                  path="/ahc-requests"
                  name="AHC Requests"
                  component={AhcRequests}
                />
                <Route
                  path="/labs-requests"
                  name="Labs Requests"
                  component={LabRxRequests}
                />
                <Route
                  path="/labs-reimbursements"
                  name="Labs Reimbursements"
                  component={LabReimbursements}
                />
                <Route
                  path="/new-lab-order-details"
                  name="Labs | Order Details"
                  component={NewLabOrderDetails}
                />
                <Route
                  path="/lab-order-details-v2"
                  name="Labs | Order Details"
                  component={LabOrderDetailsV2}
                />
                <Route
                  path="/lab-partner-details"
                  component={LabsOnboardingNavigator}
                />
                <Route
                  path="/lab-rx-details"
                  name="Labs | Rx Request Details"
                  component={LabRxRequestDetails}
                />
                <Route
                  path="/lab-create-rx"
                  name="Labs | Create New Transcription Request"
                  component={CreateTranscriptionRequest}
                />
                <Route
                  path="/online-payment"
                  name="Online Payment"
                  component={OnlinePaymentHome}
                />
                <Route
                  path="/labs-reconcile/upload-partner-tnx"
                  name="Upload Partner Transactions"
                  component={LabsUploadPartnerTnx}
                />
                <Route
                  path="/labs-reconcile/reconciled-tnx"
                  name="Labs Reconcile"
                  component={LabsReconcileHome}
                />
                <Route
                  path="/doctor-pool"
                  name="Doctor Pool"
                  component={DoctorPoolComponent}
                />
                <Route
                  path="/sponsor-pool"
                  name="Doctor Pool"
                  component={SponsorsPoolComponent}
                />
                <Route
                  path="/doctor-unattended"
                  name="Doctor Pool"
                  component={DoctorLiveStats}
                />
                <Route
                  path="/opd-management"
                  name="Opd Management"
                  component={OpdManagement}
                />
                <Route path="/products" name="Products" component={Products} />
                <Route path="/pools" name="Pools" component={Pools} />
                <Route
                  path="/sponsor-management"
                  name="Sponsor Management"
                  component={SponsorManagementNavigator}
                />
                <Route
                  path="/redemption-ops"
                  name="Redemption OPS"
                  component={RedemptionOpsNavigator}
                />
                <Route
                  path="/pharmacy-orders"
                  name="Pharmacy Orders"
                  component={PharmacyOrdersHome}
                />
                <Route
                  path="/new-pharmacy-order-details"
                  name="Pharmacy | Order Details"
                  component={NewPharmacyOrderDetails}
                />
                <Route
                  path="/online-consultation-booking"
                  name="Online Consultation Booking"
                  component={OnlineConsultationBooking}
                />
                <Route
                  path="/exceptional-reimbursement"
                  name="Exceptional Reimbursement"
                  component={ExceptionalReimbursement}
                />
                <Route
                  path="/physiotherapy-program-enrolls"
                  name="Physiotherapy Program Enrolls"
                  component={PhysioProgramEnrolls}
                />
                <Route
                  path="/intimation-requests"
                  name="Intimation Requests"
                  component={IntimationRequest}
                />
                <Route path="/coupons" name="Coupon Code" component={Coupons} />
                <Route 
                  path="/added-doctor-request-list" 
                  name="Added Doctor Request List" 
                  component={AddedDoctorRequestList} 
                />
                <Route
                  path="/cashless-vaccination"
                  name="Cashless Vaccination"
                  component={CashlessVaccination}
                />
                <Route
                  path="/cashless-dental"
                  name="Cashless Dental"
                  component={CashlessDental}
                />
                <Route
                  path="/make-credentials"
                  name="make-credentials"
                  component={makeCredentials}
                />
                <Route
                  path="/manage-credentials"
                  name="manage-credentials"
                  component={manageCredentials}
                />
                <Route
                  path="/approve-credentials"
                  name="approve-credentials"
                  component={ApproveCredentials}
                />
                {/* <Route
                  path="/reimbursement-admin"
                  name="reimbursement-admin"
                  component={ReimbursementAdmin}
                /> */}
                {/* <Route
                  path="/reimbursement-doctor"
                  name="reimbursement-doctor"
                  component={AddReimbursementDoctor}
                /> */}
                {/* <Route
                  path="/qc-reimbursement"
                  name="qc-reimbursement"
                  component={ReimbursementQcCheck}
                /> */}
                {/* <Route
                  path="/qc-remark"
                  name="qc-remark"
                  component={ReimbursementQcRemark}
                /> */}
                <Route component={() => <div>Not Found</div>} />
              </Switch>
            </BodyContainer>
          </OuterComponent>
        </Router>
        {/* <ReactQueryDevtoolsPanel initialIsOpen={false} position='bottom-left' /> */}
      </QueryClientProvider>
    );
  }
}

const mapStateToProps = (state) => {
  const showSideBar = state.app.showSideBar;

  return { showSideBar };
};

export default connect(mapStateToProps)(App);
