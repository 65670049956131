import baseRequest from './baseRequest';

export const onlineConsultationBooking = (columnsName, records, sponsorId) => {
  const url = '/new-ops/online-consultation/book-consultation';
  const method = 'POST';
  const data = {
    columnsName,
    records,
    sponsorId,
  };
  console.log({ data }, 'onlineConsultationBooking');
  return baseRequest(url, method, null, data);
};

export const getReations = () => {
  const url = `/new-ops/online-consultation/get-relation`;
  const method = 'GET'
  return baseRequest(url,method,null,null)
}
export const addDependentForUser = (data) => {
  const url = `/new-ops/online-consultation/add-dependent`;
  const method = 'POST'
  console.log(data,'[apiData]')
  return baseRequest(url,method,null,data)
}
export const getOnlineConsultDoc = (search, verticalId, consultationLanguage) => {
  const url = `/new-ops/online-consultation/searchOnlineDoctor`
  const method = 'GET';
  const query = {
      search,
      verticalId,
      consultationLanguage
  };
return baseRequest(url, method, query, null);
}
export const getUserData = (phone) => {
  const url = `/new-ops/online-consultation/get-user`
  const method = 'GET';
  const query = {
    phone
  };
  console.log(query,'query')
return baseRequest(url, method, query, null);
}

export const createUser = (userDetails)=>{
  const url = `/new-ops/users/create`;
  const method = 'POST';
  return baseRequest(url, method, null, userDetails);
}

export const bookOnlineSpecialistConsult = (details, consultationPayType )=>{
  let url = `/new-ops/consult/specialist/request`;
  if(consultationPayType == 'wallet') {
    url = `/new-ops/consult/specialist/request/v1`;
  }
  const method = 'POST';
  return baseRequest(url, method, null, details);
}
