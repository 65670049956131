import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { Button, Card, Col, Row } from 'antd';
import Select from 'react-select';

import { toast } from 'react-toastify';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import {
  addAssigneData,
  fetchOpsUsers,
  // getAllOfflineRequests,
} from '../../services/api/offline-consult';

const Text = styled.p`
  margin: 0;
  font-family: BrandonText-Medium, Lato, sans-serif;
`;

const SemiboldText = styled(Text)`
  font-family: ProximaNova-Semibold, Lato, sans-serif;
`;

const LabelText = styled(SemiboldText)`
  font-size: 12px;
  text-align: center;
  margin-bottom: 0.25rem;
  margin-top: 0.625rem;
  color: #7c7c7c;
  display: flex;
  flex-shrink: 0;
  flex-direction: row;
`;

const BodyContainer = styled.div`
  display: flex;
  flex: 1 0;
  flex-direction: column;
  align-items: stretch;
  margin-bottom: 20px;
  /* over */
`;

const AddAssigneModal = (props) => {
  const {
    isModalOpen,
    closeAssigneModal,
    requestId,
    fetchTableData,
    allCheckedReqIds,
    addAssigneFlag,
    getAllOfflineRequests,
  } = props;
  const [assigneData, setAssigneData] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState();

  useEffect(() => {
    fetchOpsUsersData();
  }, []);

  const fetchOpsUsersData = async () => {
    const data = await fetchOpsUsers();
    const allOpsUsers = data.map((user) => {
      return {
        value: user.opsUserId,
        label: user.opsUserName,
      };
    });
    setAssigneData(allOpsUsers);
  };

  function handleSelect(data) {
    setSelectedOptions(data);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    const selectedAssigne = assigneData.find(
      (item) => item.value === selectedOptions.value
    );

    if (!selectedAssigne) {
      toast.error('Assigne not found');
      return;
    }

    const body = {
      opsUserId: selectedAssigne.value,
      opsUserName: selectedAssigne.opsUserName,
      requestType: 'Add_Assigne_Details',
      requestId: requestId,
      allCheckedReqIds: allCheckedReqIds,
      addAssigneFlag: addAssigneFlag,
    };

    if (!selectedAssigne.value) {
      toast.error('Please fill all the required fields');
      return;
    }

    const data = await addAssigneData(body);
    if (data.message == 'success') {
      closeAssigneModal();
      toast.success('Assigne Requested Created Successfully');
      getAllOfflineRequests();
    }
    if (data.message == 'serverError') {
      toast.error(data.errorMessage);
    }
  };

  return (
    <Modal toggle={closeAssigneModal} isOpen={isModalOpen} centered>
      <ModalHeader
        className="revamp-modal-header"
        toggle={() => closeAssigneModal()}
      >
        {addAssigneFlag === 'addAssigne'
          ? `Assign Request (Request Id: ${requestId})`
          : `Assign Request`}
      </ModalHeader>
      <ModalBody
        style={{
          display: 'flex',
          flexDirection: 'column',
          padding: '20px',
        }}
      >
        <Card className="assignee-card">
          <BodyContainer
            style={{
              padding: '10px',
            }}
          >
            {addAssigneFlag === 'addBulkAssigne' ? (
              <div>
                <LabelText>Selected Id's:</LabelText>
                {allCheckedReqIds.map((item, i) => (
                  <li key={i}>{item}</li>
                ))}
              </div>
            ) : (
              <div></div>
            )}
            <Row>
              <Col>
                <LabelText>Select Assigne:</LabelText>
              </Col>
              <Col
                style={{
                  marginLeft: '20px',
                }}
              >
                <Select
                  className="assigne-select"
                  options={assigneData}
                  placeholder="Select user"
                  value={selectedOptions}
                  onChange={handleSelect}
                  isSearchable={true}
                />
              </Col>
            </Row>
            <Row gutter={24} style={{ marginTop: 20 }}>
              <Col xs={24} md={12}>
                <Button
                  style={{
                    backgroundColor: 'rgb(113, 79, 255)',
                    width: '100%',
                    borderColor: 'rgb(113, 79, 255)',
                    height: '44px',
                    color: 'white',
                    borderRadius: '4px',
                  }}
                  block
                  onClick={(e) => handleSubmit(e)}
                >
                  Add
                </Button>
              </Col>
              <Col xs={24} md={12}>
                <Button
                  style={{
                    width: '100%',
                    height: '44px',
                    borderRadius: '4px',
                  }}
                  block
                  onClick={closeAssigneModal}
                >
                  Cancel
                </Button>
              </Col>
            </Row>
          </BodyContainer>
        </Card>
      </ModalBody>
    </Modal>
  );
};

export default AddAssigneModal;
