import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Modal, ModalBody, ModalHeader, Spinner } from 'reactstrap';
import axios from 'axios';
import config from '../../config.js';
import { store } from '../../store/index.js';

export async function getScanAndPayCartDetailByCartId(cartId) {
    const headers = {};
    const state = store.getState();
    if (state.user.token) {
        headers.authorization = state.user.token;
    }

    try {
        const response = await axios.get(
            `${config.pharmacyService}/scan-and-pay/fetch-cart-detail?cartId=${cartId}`,
            { headers }
        );
        return response.data;
    } catch (err) {
        const errorMessage = err.response && err.response.data && err.response.data.message || err.message;
        throw new Error(errorMessage);
    }
}

export async function getCallList(offset = 0) {
    const headers = {};
    const state = store.getState();
    if (state.user.token) {
        headers.authorization = state.user.token;
    }

    try {
        const response = await axios.get(
            `${config.pharmacyService}/scan-and-pay/fetch-call-list?offset=${offset}`,
            { headers }
        );
        return response.data;
    } catch (err) {
        const errorMessage = err.response && err.response.data && err.response.data.message || err.message;
        throw new Error(errorMessage);
    }
}

export async function updateCartApprovedAmount(cartId, approvedAmount) {
    const headers = {
        'Content-Type': 'application/json',
    };
    const state = store.getState();
    if (state.user.token) {
        headers.Authorization = state.user.token;
    }

    try {
        const response = await axios.post(
            `${config.pharmacyService}/scan-and-pay/update-cart-approved-amount`,
            { cartId, approvedAmount },
            { headers }
        );
        return response.data;
    } catch (err) {
        const errorMessage = err.response && err.response.data && err.response.data.message || err.message;
        throw new Error(errorMessage);
    }
}

function UpdateApprovedAmountModal({ onClose }) {
    const [cartId, setCartId] = useState(''); // State for cartId input
    const [cartDetails, setCartDetails] = useState({
        items: { covered: [], uncovered: [] },
        totalAmount: 0,
        requestedAmount: 0,
        approvedAmount: 0,
        prescriptionUrl: '',
        cartId: null,
    });
    const [approvedAmount, setApprovedAmount] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [updating, setUpdating] = useState(false);
    const [callList, setCallList] = useState([]);  // New state for call list data
    const [callListLoading, setCallListLoading] = useState(true); // Loading state for call list
    const [offset, setOffset] = useState(0);  // State for offset

    // Fetch cart details when the cartId is submitted
    const fetchCartDetails = async (cartId) => {
        setLoading(true);
        try {
            const data = await getScanAndPayCartDetailByCartId(cartId);
            setError('');
            setCartDetails(data.cart);
            setApprovedAmount(data.cart.approvedAmount);
        } catch (err) {
            setCartDetails({
                items: { covered: [], uncovered: [] },
                totalAmount: 0,
                requestedAmount: 0,
                approvedAmount: 0,
                prescriptionUrl: '',
                cartId: null,
            });
            setError(err.message);
        }
        setLoading(false);
    };

    // Fetch call list with an offset
    const fetchCallList = async (offset = 0) => {
        setCallListLoading(true);
        try {
            const data = await getCallList(offset);
            setCallList(data.callList); 
        } catch (err) {
            setError(err.message);
        }
        setCallListLoading(false);
    };

    // Fetch data when component mounts
    useEffect(() => {
        fetchCallList(offset);
    }, [offset]);  // Re-fetch call list when offset changes

    const handleCartIdSubmit = (e) => {
        e.preventDefault();
        if (cartId) {
            fetchCartDetails(cartId);
        } else {
            setError('Please enter a valid cart ID');
        }
    };

    // Update approved amount
    const handleApprovedAmountSubmit = async () => {
        if (!approvedAmount || isNaN(approvedAmount)) {
            setError('Please enter a valid approved amount');
            return;
        }

        setUpdating(true);
        try {
            await updateCartApprovedAmount(cartId, approvedAmount);
            setError('');
            fetchCartDetails(cartId); // Refresh the cart details after update
        } catch (err) {
            setError('Failed to update approved amount. Please try again.');
        }
        setUpdating(false);
    };

    // Increment offset
    const handleNextPage = () => {
        setOffset(offset + 1);  // Increase offset by 1
    };

    // Decrement offset
    const handlePrevPage = () => {
        if (offset > 0) {
            setOffset(offset - 1);  // Decrease offset by 1
        }
    };

    return (
        <Modal isOpen={true} style={{ maxWidth: '1800px', width: '100%', borderRadius: '4px', backgroundColor: '#fff', textAlign:'center' }}>
                <ModalHeader style={{ backgroundColor: '#fff' }} toggle={onClose}>
                <h3 className="text-center" style={{ color: '#343a40', fontWeight: 'bold', fontFamily: 'sans-serif' }}>Scan & Pay Update Approved Amount</h3>   
                </ModalHeader>
            <ModalBody style={{ padding: '20px 40px' }}>
                {error && <p className="text-danger text-center">{error}</p>}
                <hr></hr>
                {/* Call List Section */}
                <div
                    className="call-list mt-4"
                    style={{
                        borderRadius: '8px',
                        background: 'linear-gradient(145deg, #ffffff, #f0f0f0)',
                        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08)',
                        transition: 'box-shadow 0.3s ease-in-out, transform 0.2s ease',
                        padding: '20px',
                    }}
                >
                    {callListLoading ? (
                        <div className="text-center">
                            <Spinner />
                        </div>
                    ) : (
                        <>
                            <table
                                className="table table-bordered table-striped"
                                style={{
                                    width: '100%',
                                    borderRadius: '8px',
                                    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                                    marginBottom: '20px',
                                }}
                            >
                                <thead
                                    className="thead-dark"
                                    style={{
                                        background: '#343a40',
                                        color: '#fff',
                                        textAlign: 'center',
                                    }}
                                >
                                    <tr>
                                        <th>Is Closed</th> 
                                        <th>Operator Name</th>
                                        <th>Operator Phone</th>
                                        <th>User Name</th>
                                        <th>User Phone</th>
                                        <th>User Type</th>
                                        <th>Cart ID</th>
                                        <th>Call Status</th>
                                        <th>Start Time</th>
                                        <th>End Time</th>
                                        <th>Duration</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {callList.map((call, index) => (
                                        <tr key={index} style={{ textAlign: 'center' }}>
                                            <td>{call.isClosed ? 'YES' : 'NO'}</td>
                                            <td>{call.opsUserName}</td>
                                            <td>{call.opsUserPhone}</td>
                                            <td>{call.userName}</td>
                                            <td>{call.userPhone}</td>
                                            <td>{call.isVipUser ? 'VIP' : 'Non-VIP'}</td>
                                            <td style={{ textDecorationLine: 'underline'}} onClick={() => { setCartId(call.cartId); fetchCartDetails(call.cartId);  }}>{call.cartId}</td>
                                            <td>{call.callStatus}</td>
                                            <td>{call.startTime}</td>
                                            <td>{call.endTime}</td>
                                            <td>{call.duration}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>

                            {/* Pagination Controls */}
                            <div
                                className="d-flex justify-content-center mt-3"
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    gap: '10px',
                                    marginTop: '20px',
                                }}
                            >
                                <button
                                    className="btn btn-secondary mr-3"
                                    onClick={handlePrevPage}
                                    disabled={offset === 0}
                                    style={{
                                        padding: '10px 20px',
                                        fontSize: '18px',
                                        borderRadius: '5px',
                                        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                                        transition: 'box-shadow 0.3s ease, transform 0.2s ease',
                                    }}
                                >
                                    PREV
                                </button>
                                <span
                                    style={{
                                        fontSize: '18px',
                                        fontWeight: 'bold',
                                        color: '#343a40',
                                    }}
                                >
                                    Page {offset + 1}
                                </span>
                                <button
                                    className="btn btn-secondary ml-3"
                                    onClick={handleNextPage}
                                    style={{
                                        padding: '10px 20px',
                                        fontSize: '18px',
                                        borderRadius: '5px',
                                        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                                        transition: 'box-shadow 0.3s ease, transform 0.2s ease',
                                    }}
                                >
                                    NEXT
                                </button>
                            </div>
                        </>
                    )}
                </div>
                <hr></hr>
                <section style={{ width: '100%', display: 'inline-flex', justifyContent: 'space-evenly'}}>
                    {/* Cart ID input field */}
                    <form
                    onSubmit={handleCartIdSubmit}
                    className="mt-4"
                        style={{
                        width:'50%',
                        borderRadius: '8px',
                        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08)',
                        transition: 'box-shadow 0.3s ease-in-out, transform 0.2s ease',
                        padding: '15px'
                    }}
                    >
                    <label
                        htmlFor="cartId"
                        style={{
                            fontSize: '18px',
                            fontWeight: 'bold',
                            color: '#007bff',
                            marginBottom: '10px',
                            display: 'block',
                        }}
                    >
                        <h5>Enter Cart ID:</h5>
                    </label>
                    <center>
                        <input
                            type="text"
                            id="cartId"
                            className="form-control"
                            value={cartId}
                            style={{
                                width: '50%',
                                fontSize: '24px',
                                fontWeight: 'bold',
                                color: 'black',
                                padding: '10px',
                                borderRadius: '8px',
                                boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                                marginBottom: '15px',
                                transition: 'box-shadow 0.3s ease',
                            }}
                            onChange={(e) => setCartId(e.target.value)}
                            required
                        />
                    </center>
                    <button
                        type="submit"
                        className="btn btn-primary mt-3 btn-lg"
                        disabled={loading}
                        style={{
                            padding: '10px 20px',
                            fontSize: '16px',
                            borderRadius: '5px',
                            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                            transition: 'box-shadow 0.3s ease, transform 0.2s ease',
                        }}
                    >
                        {loading ? <Spinner size="lg" /> : 'Fetch Cart Details'}
                    </button>
                    </form>
                    {/* Requested and Approved Amount */}
                    <div
                        className="mt-4"
                        style={{
                            width: '100%',
                            borderRadius: '8px',
                            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08)',
                            transition: 'box-shadow 0.3s ease-in-out, transform 0.2s ease',
                            padding: '15px'
                        }}
                    >
                        <h5
                            className="card-header"
                            style={{
                                backgroundColor: '#17a2b8',
                                color: 'white',
                                borderRadius: '8px 8px 0 0',
                                fontSize: '18px',
                                padding: '10px'
                            }}
                        >
                            Requested and Approved Amount
                        </h5>
                        <div className="card-body">
                            <h5>Requested Amount: ₹{cartDetails.requestedAmount}</h5>
                            <h5>Approved Amount: ₹{cartDetails.approvedAmount}</h5>
                            <center>
                                <input
                                    type="number"
                                    value={approvedAmount}
                                    onChange={(e) => setApprovedAmount(e.target.value)}
                                    className="form-control"
                                    style={{
                                        width: '50%',
                                        fontSize: '24px',
                                        fontWeight: 'bold',
                                        color: 'black',
                                        padding: '10px',
                                        borderRadius: '8px',
                                        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                                        marginBottom: '15px',
                                        transition: 'box-shadow 0.3s ease',
                                    }}
                                />
                            </center>
                            <button
                                type="button"
                                className="btn btn-primary mt-3 btn-lg"
                                onClick={handleApprovedAmountSubmit}
                                disabled={updating}
                                style={{
                                    padding: '10px 20px',
                                    fontSize: '16px',
                                    borderRadius: '5px'
                                }}
                            >
                                {updating ? <Spinner size="lg" /> : 'Update Approved Amount'}
                            </button>
                        </div>
                    </div>
                </section>

                {/* Loading spinner */}
                {loading && <div className="text-center"><Spinner /></div>}

                {/* Display cart details in separate tables */}
                {!loading && (
                    <>
                        {cartDetails.prescriptionUrl && (
                            <div className="text-center form-group mt-3">
                                <h5>
                                    <a
                                        href={cartDetails.prescriptionUrl}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        style={{
                                            fontWeight: 'bold',
                                            textDecoration: 'underline',
                                            color: '#007bff'
                                        }}
                                    >
                                        View Prescription
                                    </a>
                                </h5>
                            </div>
                        )}
                        <section style={{display: 'inline-flex', justifyContent: 'space-evenly'}}>
                            {/* Covered Items Table */}
                            {cartDetails.items.covered.length > 0 && (
                                <div
                                    className="mt-4"
                                    style={{
                                        borderRadius: '8px',
                                        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08)',
                                        transition: 'box-shadow 0.3s ease-in-out, transform 0.2s ease',
                                        padding: '15px',
                                        marginBottom: '20px'
                                    }}
                                >
                                    <h5
                                        className="card-header"
                                        style={{
                                            backgroundColor: '#007bff',
                                            color: 'white',
                                            borderRadius: '8px 8px 0 0',
                                            fontSize: '18px',
                                            padding: '10px'
                                        }}
                                    >
                                        Covered Items
                                    </h5>
                                    <div className="card-body">
                                        <table
                                            className="table table-bordered table-striped"
                                            style={{
                                                borderRadius: '8px',
                                                backgroundColor: '#f8f9fa',
                                                boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)'
                                            }}
                                        >
                                            <thead className="thead-dark">
                                                <tr>
                                                    <th>Drug Name</th>
                                                    <th>MRP (₹)</th>
                                                    <th>Quantity</th>
                                                    <th>Is Covered</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {cartDetails.items.covered.map((item, index) => (
                                                    <tr key={index}>
                                                        <td>{item.name}</td>
                                                        <td>{item.mrp}</td>
                                                        <td>{item.quantity}</td>
                                                        <td>{item.isCovered ? 'Yes' : 'No'}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            )}

                            {/* Uncovered Items Table */}
                            {cartDetails.items.uncovered.length > 0 && (
                                <div
                                    className="mt-4"
                                    style={{
                                        borderRadius: '8px',
                                        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08)',
                                        transition: 'box-shadow 0.3s ease-in-out, transform 0.2s ease',
                                        padding: '15px',
                                        marginBottom: '20px'
                                    }}
                                >
                                    <h5
                                        className="card-header"
                                        style={{
                                            backgroundColor: '#ffc107',
                                            color: 'white',
                                            borderRadius: '8px 8px 0 0',
                                            fontSize: '18px',
                                            padding: '10px'
                                        }}
                                    >
                                        Uncovered Items
                                    </h5>
                                    <div className="card-body">
                                        <table
                                            className="table table-bordered table-striped"
                                            style={{
                                                borderRadius: '8px',
                                                backgroundColor: '#f8f9fa',
                                                boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)'
                                            }}
                                        >
                                            <thead className="thead-dark">
                                                <tr>
                                                    <th>Drug Name</th>
                                                    <th>MRP (₹)</th>
                                                    <th>Quantity</th>
                                                    <th>Is Covered</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {cartDetails.items.uncovered.map((item, index) => (
                                                    <tr key={index}>
                                                        <td>{item.name}</td>
                                                        <td>{item.mrp}</td>
                                                        <td>{item.quantity}</td>
                                                        <td>{item.isCovered ? 'Yes' : 'No'}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            )}
                        </section>
                    </>
                )}

            </ModalBody>
        </Modal>
    );
}

const mapStateToProps = (state) => ({
    authToken: state.user.authToken,
    user: state.user,
});

export default connect(mapStateToProps)(UpdateApprovedAmountModal);
