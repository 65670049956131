import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import NavBar from '../layout/NavBar';
import SearchIcon from '../../images/SearchIcon.svg';
import BookRequestModal from './BookRequestModal';
import LoadingComponent from '../common/LoadingComponent';
import filterbutton from '../../images/filterbutton.svg';
import { toast } from 'react-toastify';
import {
  Tooltip,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  ButtonGroup,
  FormGroup,
  Input as InputBox,
  Spinner,
} from 'reactstrap';
import { getAllOfflineRequests, markOpsUserCheckIn, markOpsUserCheckOut } from '../../services/api/offline-consult';
import {
  getAllOfflineRequestsCount,
  sendPriceChangeRequestAPI,
  confirmRequest,
  cancelRequest,
  fetchCancelledReasons,
  shiftOfflineConsultationToReimbursement,
  requestReconfirmationForOfflineConsult,
  placeRequest,
  getOPDAgentStatus
} from '../../services/api/offline-consult';
import { Select, message, DatePicker, Checkbox } from 'antd';
import PenButton from '../../images/PenButton.svg';
import TickButton from '../../images/TickButton.svg';
import CrossButton from '../../images/CrossButton.svg';
import DocsButton from '../../images/DocsButton.svg';
import TimerButton from '../../images/TimerButton.svg';
import SomeButton from '../../images/SomeButton.svg';
import DropDown from '../../images/DropDown.svg';
import ClaimRequest from '../../images/ClaimRequest.svg';
import AddAssignee from '../../images/AddAssignee.svg';
import AlternateSuggestions from '../../images/AlternateSuggestions.svg';
import ShiftToReimbursement from '../../images/ShiftToReimbursement.svg';
import ShiftToPending from '../../images/ShiftToPending.svg';
import RequestReconfirmation from '../../images/RequestReconfirmation.svg';
import MagnifyingGlass from '../../images/MagnifyingGlass.svg';
// import './Revamp.css';
import ShowActions from '../../images/ShowActions.svg';
import UnhideActions from '../../images/UnhideActions.svg';
import { debounce } from 'lodash';
import styled from 'styled-components';
import StatusFilter from './StatusFilter';
import ClaimedByFilter from './ClaimedByFilter';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import Pagination from './PaginationComponent';
// import {
//   StatusPendingContainer,
//   StatusCompleteContainer,
//   StatusDangerContainer,
//   StatusCancelledContainer,
//   StatusUserCancelledContainer,
// } from '../common/StatusContainer';
import {
  StatusPendingContainer,
  StatusCompleteContainer,
  StatusDangerContainer,
  StatusCancelledContainer,
  StatusUserCancelledContainer,
} from './StatusContainer';

import moment, { max } from 'moment';
import { RegularText } from '../common/Text';
import RidSearch from './RidSearch';
import { fetchAllSponsors } from '../../services/api/sponsors';
import AckFilter from './AckFilter';
import PayoutFilter from './PayoutFilter';
import CallingButton from './CallingButton';
import OnboardingStatusFilter from './OnboardingStatusFilter';
import NetworkSourceFilter from './NetworkSourceFilter';
import EditDoctorSlots from './EditDoctorSlots';
import OfflineModalConfirm from './OfflineModalConfirm';
import OfflineModalCancel from './OfflineModalCancel';
import ClaimRequestModal from './ClaimRequestModal';
import SuggestionsDialog from './SuggestionsDialog';
import PlaceRequest from './PlaceRequest';
import RequestDetails from './RequestDetails';
import { callUserRequest } from '../../services/api/offline-consult';
import RescheduleAppointMentRequest from './RescheduleAppointMentRequest';
import ConfirmModal from './ConfirmModal';
import SubComponent from './SubComponent';
import './revamp-react-table.css';

import {
  showRequestModal,
  hideRequestModal,
  hideDoctorSlotsEditModal,
  showDoctorSlotsEditModal,
  // showInternalComments,
  hideInternalComments,
  updateRequestID,
} from '../../actions/index';
import AddDoctorDetailModal from './AddDoctorDetailModal';
import Actions from './Actions';
import MarkEscallationModal from './MarkEscallationModal';
import DownloadCsv from './DownloadCsv';
import AddAssigneModal from './AddAssigneModal';
import NetworkCenterIcon from '../offline-consultations/table-subcomponent/NetworkCenterIcon';
import { FaTheRedYeti } from 'react-icons/fa';
import TagsFilter from './TagsFilter';
import ChangeProcessingDateModal from '../offline-consultations/ChangeProcessingDateModal';
import events from 'inquirer/lib/utils/events';
import FetchFollowUpModal from './FetchFollowUpModal';

const reasonOptions = [
  { label: 'All', value: null },
  { label: 'User is unresponsive to 3 call attempts', value: 'User is unresponsive to 3 call attempts' },
  { label: 'Center is unresponsive to 3 call attempts', value: 'Center is unresponsive to 3 call attempts' },
  { label: 'User preferred slot is unavailable and no alternatives found', value: 'User preferred slot is unavailable and no alternatives found' },
  { label: 'Center is denying appointment due to tie-up/pending payment issue', value: 'Center is denying appointment due to tie-up/pending payment issue' },
  { label: 'Center is temporarily/permanently closed, and no alternatives found', value: 'Center is temporarily/permanently closed, and no alternatives found' },
  { label: 'Doctor is temporarily/permanently unavailable, and no alternatives found', value: 'Doctor is temporarily/permanently unavailable, and no alternatives found' },
  { label: 'All Stuck Cases', value: 'all' },

];

// const DefaultRequestDateFilter = () => {
//   return {
//     startDate: moment().subtract(31, 'days'),
//     endDate: moment(),
//   };
// };
const ReavampOfflineConsultations = (props) => {
  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(false);
  const [countLoader, setCountLoader] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [noData, setNoData] = useState(false);
  const [segregation, setSegregation] = useState(null);
  const [currentButtonStatus, setCurrentButtonStatus] = useState('');
  const [cols, setCols] = useState([]);
  const [draw, setDraw] = useState(0);
  const [pageSize, setPageSize] = useState(20);
  const [totalHits, setTotalHits] = useState(0);
  const [pages, setPages] = useState(0);
  const [statusFilter, setStatusFilter] = useState(null);
  const [orderBy, setOrderBy] = useState(null);
  const [orderDir, setOrderDir] = useState(null);
  const [tagsFilter, setTagsFilter] = useState(null);
  const [claimedByFilter, setClaimedByFilter] = useState('');
  const [page, setPage] = useState(0);
  const [filterRequestId, setFilterRequestId] = useState('');
  const [patientName, setPatientName] = useState('');
  const [doctorName, setDoctorName] = useState('');
  const [centerName, setCenterName] = useState('');
  const [sponsorName, setSponsorName] = useState('');
  const [sponsorList, setSponsorList] = useState([]);
  const [sponsorFilter, setSponsorFilter] = useState(null);
  const [stuckReasonFilter, setStuckReasonFilter] = useState(null);
  const [ackFilter, setAckFilter] = useState(null);
  const [buttonActive, setButtonActive] = useState(null);
  const [payoutFilter, setPayoutFilter] = useState(null);
  const [appointmentStartDate, setAppointmentStartDate] = useState(null);
  const [appointmentEndDate, setAppointmentEndDate] = useState(null);
  const [requestStartDate, setRequestStartDate] = useState(moment().subtract(30, 'days').startOf('day').format('YYYY-MM-DD HH:mm'));
  const [requestEndDate, setRequestEndDate] = useState(moment().endOf('day').format('YYYY-MM-DD HH:mm'));
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [allChecked, setAllChecked] = useState([]);
  const [processingStartDate, setProcessingStartDate] = useState(null);
  const [processingEndDate, setProcessingEndDate] = useState(null);
  const [onboardingStatusFilter, setOnboardingStatusFilter] = useState(null);
  const [paymentCycleFilter, setPaymentCycleFilter] = useState(null);
  const [searchRequestId, setSearchRequestId] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [transferTooltipOpen, setTransferTooltipOpen] = useState(-1);
  const [shiftingDetails, setShiftingDetails] = useState(null);
  const [showShiftingModal, setShowShiftingModal] = useState(false);
  const [priceChangeRequestsModal, setPriceChangeRequestsModal] =
    useState(false);
  const [requestIdDataForPriceChange, setRequestIdDataForPriceChange] =
    useState(null);
  const [newPrice, setNewPrice] = useState('');
  const [priceChangeReason, setPriceChangeReason] = useState('');
  const [inputReason, setInputReason] = useState('');
  const [addDoctorDetailModalOpen, setAddDoctorDetailModalOpen] =
    useState(false);
  const [followUpDetails, setFollowUpDetails] = useState(null);
  const [confirmModalIsOpen, setConfirmModalIsOpen] = useState(false);
  const [requestDataForConfirm, setRequestDataForConfirm] = useState(null);
  const [centerRequestStatus, setCenterRequestStatus] = useState(null);
  const [selectedDateRange, setSelectedDateRange] = useState(null);
  const [selectedRequestDateRange, setSelectedRequestDateRange] = useState(null);
  const [selectedProcessingDateRange, setSelectedProcessingDateRange] = useState(null);
  const [cancelModalIsOpen, setCancelModalIsOpen] = useState(false);
  const [openActionDropDown, setOpenActionDropDown] = useState([]);
  const [showClaimRequestModal, setShowClaimRequestModal] = useState(false);
  const [showClaimRequestModalRequestId, setShowClaimRequestModalRequestId] =
    useState(null);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [suggestionsDetails, setSuggestionsDetails] = useState(null);
  const [shiftReimbursementModalOpen, setShiftReimbursementModalOpen] =
    useState(false);
  const [shiftToReimbursementReqId, setShiftToReimbursementReqId] =
    useState(null);
  const [requestReconfirmationModalOpen, setRequestReconfirmationModalOpen] =
    useState(false);
  const [checkInModalOpen, setCheckInModalOpen] = useState(false);
  const [requestReconfirmationRequestId, setRequestReconfirmationRequestId] =
    useState(null);
  const [currentRequestDetails, setCurrentRequestDetails] = useState({});
  const [isCallModalOpen, setIsCallModalOpen] = useState(false);
  const [addAssigneModalOpen, setAddAssigneModalOpen] = useState(false);
  // const [addAssigneReqId, setAddAssigneReqId] = useState(null);
  const [addAssigneFlag, setAddAssigneFlag] = useState(false);
  const [toggleActions , setToggleActions] = useState(true);
  const [callReasonOptions, setCallReasonOptions] = useState([
    'a pending action on the rescheduled request from you',
    'a change in the price',
    'the required reconfirmation from you',
    'the doctor’s unavailability and available alternatives',
    'the slot’s unavailability and available alternatives',
    'a pending confirmation from the centre',
    "an update",
    'a pending prescription upload',
  ]);
  const [showFetchFollowUpModal, setShowFetchFollowUpModal] = useState(false);
  const [fetchFollowUpDetails, setFetchFollowUpDetails] = useState(null);
  const [selectedCallReason, setSelectedCallReason] = useState('');
  const [colorGreading, setColorGreading] = useState(false);
  const [preBooked, setPreBooked] = useState(true);
  const [opsUserStatus, setOpsUserStatus] = useState(null);
  const [groupId, setGroupId] = useState(null);
  const [basicInfoFromChild, setBasicInfoFromChild] = useState({});
  const [state, setState] = useState({
    requestDataForConfirm: {},
    shiftingDetails: {},
    currentRequestDetails: {},
  });
  const [confirmModal, setConfirmModal] = useState({
    isOpen: false,
    header: '',
    body: '',
    requestId: null,
    onSubmit: null,
    onClose: null,
    buttonLoading: false,
  });
  const [changeProcessingDateModal, setChangeProcessingDateModal] = useState({
    isOpen : false,
    requestId: null,
    processingDate: null
  });
  const [checkInOutLoader, setCheckInCheckOutLoader] = useState(false);

  const toggleDropdown = (dropdown) => {
    setOpenActionDropDown(dropdown);
    setIsOpen(!isOpen);
  };

  const handleSelect = (option) => {
    console.log('You selected:', option);
    // Close the dropdown
    setIsOpen(false);
  };
  const { RangePicker } = DatePicker;
  const dispatch = useDispatch();
  const { Option } = Select;

  const {
    showRequestDetails,
    showInternalComments,
    requestId,
    showEditDoctorSlots,
    doctorId,
  } = useSelector((state) => state.offlineConsult);

  const {
    userId
  } = useSelector((state) => state.user);

  console.log('requestId', requestId, hideRequestModal);

  const debouncedSetFilterRequestId = debounce(setFilterRequestId, 1500);

  const debouncedSetCenterName = debounce(setCenterName, 900);
  const debouncedSetDoctorName = debounce(setDoctorName, 900);
  const debouncedSetPatientName = debounce(setPatientName, 900);

  const filterData = (state, instance) => {
    console.log('filter---', state.filtered);
    const { filtered } = state;
    if (filtered.length == 0) {
      debouncedSetFilterRequestId('');
    }
    if (state.sorted && state.sorted.length > 0) {
      setOrderBy(state.sorted[0].id);
      setOrderDir(state.sorted[0].desc ? 'desc' : 'asc');
    } else {
      setOrderBy(1);
      setOrderDir('desc');
    }
    if(state.filtered && state.filtered.length == 0){
      setClaimedByFilter('');
    }
    state.filtered.forEach((f) => {
      if (f.id === 'claimedUserName') {
        console.log(f.value,'claimedUserName')
        setClaimedByFilter(f.value);
      }
      if (f.id === 'requestId') {
        debouncedSetFilterRequestId(f.value);
        // dispatch(updateRequestID(f.value));
      }
      if (f.id === 'sponsorName') {
        setSponsorFilter(f.value);
      }
      if (f.id === 'patientName') {
        debouncedSetPatientName(f.value);
      }
      if (f.id === 'doctorName') {
        debouncedSetDoctorName(f.value);
      }
      if (f.id === 'networkCenterName') {
        debouncedSetCenterName(f.value);
      }
      if (f.id === 'acknowledgementCall') {
        setAckFilter(f.value);
      }
      if (f.id === 'payoutStatus') {
        setPayoutFilter(f.value);
      }
      // if (f.id === 'preBooked') {
      //   setPreBooked(f.value);
      // }
    });
    setPageSize(state.pageSize);
    setPage(state.page);
  };

  console.log(segregation, 'segregation value');

  const params = {
    draw: pageSize,
    page: page,
    segregation: segregation,
    statusFilter: statusFilter,
    tagsFilter: tagsFilter,
    claimedByFilter: claimedByFilter,
    orderBy: orderBy,
    orderDir: orderDir,
    sponsorFilter,
    appointmentDateFilterStartDate: appointmentStartDate ? moment(appointmentStartDate).format('YYYY-MM-DD HH:mm') : null,
    appointmentDateFilterEndDate: appointmentEndDate ? moment(appointmentEndDate).format('YYYY-MM-DD HH:mm') : null,
    requestDateFilterStartDate: requestStartDate,
    requestDateFilterEndDate: requestEndDate,
    processingDateFilterStartDate: processingStartDate,
    processingDateFilterEndDate: processingEndDate,
    patientNameFilter: patientName,
    doctorNameFilter: doctorName,
    networkCenterNameFilter: centerName,
    acknowledgementCallFilter: ackFilter,
    payoutFilter: payoutFilter,
    onboardingStatusFilter: onboardingStatusFilter,
    paymentCycleFilter: paymentCycleFilter,
    csv: true,
    stuckReasonFilter
  };

  const getOfflineRequestData = () => {
    console.log('calling api getOfflineRequestData', orderBy, orderDir);
    setLoading(true);
    setCountLoader(true);

    const params = {
      draw: pageSize,
      page: page,
      statusFilter: statusFilter,
      tagsFilter: tagsFilter,
      claimedByFilter: claimedByFilter,
      orderBy: orderBy,
      orderDir: orderDir,
      sponsorFilter,
      // appointmentDateFilterStartDate: appointmentStartDate ? moment(appointmentStartDate).format('YYYY-MM-DD HH:mm') : null,
      // appointmentDateFilterEndDate: appointmentEndDate ? moment(appointmentEndDate).format('YYYY-MM-DD HH:mm') : null,
      // requestDateFilterStartDate: requestStartDate,
      // requestDateFilterEndDate: requestEndDate,
      processingDateFilterStartDate: processingStartDate,
      processingDateFilterEndDate: processingEndDate,
      patientNameFilter: patientName,
      doctorNameFilter: doctorName,
      networkCenterNameFilter: centerName,
      acknowledgementCallFilter: ackFilter,
      payoutFilter: payoutFilter,
      onboardingStatusFilter: onboardingStatusFilter,
      paymentCycleFilter: paymentCycleFilter,
      stuckReasonFilter
    };
    if (search) {
      params.search = search;
    }
    if (filterRequestId) {
      params.requestId = filterRequestId;
    }
    console.log(segregation, 'segregation value while calling api');
    if (segregation !== null) {
      params.segregation = segregation;
      params.isRescheduledFilter = segregation === 3 ? 1 : null;
      params.isReconfirmationRequiredFilter = segregation === 4 ? 1 : null;
      params.isReconfirmationFilter = segregation === 5 ? 1 : null;
      params.isPendingCallbackFilter = segregation === 6 ? 1 : null;
      params.isEmergencyCalls = segregation === 8 ? 1 : null;
      if (segregation === 10) {
        params.delayedResponse = segregation === 10 ? true : false;
      }
    }

    if (
      appointmentStartDate &&
      appointmentEndDate
    ) {
      params.appointmentDateFilterStartDate = appointmentStartDate ? moment(appointmentStartDate).format('YYYY-MM-DD HH:mm') : null;
      params.appointmentDateFilterEndDate = appointmentEndDate ? moment(appointmentEndDate).format('YYYY-MM-DD HH:mm') : null;  
    }
    if (!(filterRequestId || centerName || doctorName || patientName || search)) {
      params.appointmentDateFilterStartDate = appointmentStartDate ? moment(appointmentStartDate).format('YYYY-MM-DD HH:mm') : null;
      params.appointmentDateFilterEndDate = appointmentEndDate ? moment(appointmentEndDate).format('YYYY-MM-DD HH:mm') : null;
      params.requestDateFilterStartDate = requestStartDate;
      params.requestDateFilterEndDate = requestEndDate;
    }

    console.log('Fetching offline requests...');
    getAllOfflineRequests(params)
      .then((res) => {
        console.log('Offline requests:', res);
        if (!res.result) {
          setNoData(true);
          setDataSource(res);
        } else {
          setNoData(false);
          setDataSource(res.result);
        }
        setLoading(false);
        return getAllOfflineRequestsCount(params);
      })
      .then((res) => {
        setCountLoader(false);
        console.log('Offline requests count:', res);
        setPages(Math.ceil(res.totalHits / pageSize));
        setTotalHits(res.totalHits || 0);
      })
      .catch((err) => {
        console.error('Error fetching offline requests:', err);
        setLoading(false);
        setCountLoader(false);
      });
  };

  const sendPriceChangeRequest = () => {
    setLoading(true);
    console.log(requestIdDataForPriceChange, 'requestIdDataForPriceChange');
    const params = {
      requestId: requestIdDataForPriceChange.requestId,
      centerId: requestIdDataForPriceChange.networkCenterId,
      doctorId: requestIdDataForPriceChange.doctorId,
      newPrice: newPrice,
      doctorName: requestIdDataForPriceChange.doctorName,
      centerName: requestIdDataForPriceChange.networkCenterName,
      fullfillmentType:
        requestIdDataForPriceChange.fullfillmentType || 'center',
      onBoardingStatus: requestIdDataForPriceChange.onBoardingStatus,
      priceChangeReason:
        priceChangeReason === 'Other' ? inputReason : priceChangeReason,
    };
    console.log('Price Change Request', params);
    return sendPriceChangeRequestAPI(params)
      .then((resp) => {
        if (resp.message === 'success') {
          toast.success('Successfully Requested For Price Change');
          // onFetchData();
        }
        toast.error(resp.errorMessage);
      })
      .catch((err) => {
        console.log(err);
        toast.error('Something went wrong');
      })
      .finally(() => {
        setLoading(false);
        togglePriceChangeRequest();
      });
  };

  const onAction = () => {
    getOfflineRequestData();
  };

  const handleChange = (value) => {
    setSearch(value);
  };
  const selectRow = (value) => {
    setPageSize(value);
  };
  const handleSponsor = (value) => {
    console.log(value, 'Sponsor Object');
    setSponsorFilter(value);
  };

const handlePatientNameChange = (value) => {
    console.log(value, 'Patient Name');
    const patientValue = value ? value.trim() : value;
    debouncedSetPatientName(patientValue);
  }

  const handleDoctorNameChange = (value) => {
    console.log(value, 'Doctor Name');
    const doctorValue = value ? value.trim() : value;
    debouncedSetDoctorName(doctorValue);
  }

  const handleCenterNameChange = (value) => {
    console.log(value, 'Center Name');
    const centerValue = value ? value.trim() : value;
    debouncedSetCenterName(centerValue);
  }
  const handleStuckReason = (value) => {
    console.log(value, 'Sponsor Object');
    setStuckReasonFilter(value);
  };

  const clearFilter = () => {
    setSegregation(null);
    setStatusFilter(null);
    setTagsFilter(null);
    setButtonActive(null);
    setClaimedByFilter(null);
    setSponsorFilter(null);
    setPreBooked(false);
    setAppointmentStartDate(null);
    setAppointmentEndDate(null);
    setRequestStartDate(null);
    setRequestEndDate(null);
    setProcessingStartDate(null);
    setProcessingEndDate(null);
    setPatientName('');
    setDoctorName('');
    setCenterName('');
    setAckFilter(null);
    setPayoutFilter(null);
  };

  const isFilterApplied = [
    segregation,
    statusFilter,
    tagsFilter,
    claimedByFilter,
    sponsorFilter,
    ackFilter,
    appointmentStartDate,
    appointmentEndDate,
    patientName,
    requestEndDate,
    requestStartDate,
    processingStartDate,
    processingEndDate,
    doctorName,
    payoutFilter,
    stuckReasonFilter
  ].some((filter) => filter !== null && filter !== undefined && filter !== '');

  const openShiftingModal = (details) => {
    console.log({ details }, 'openShiftingModal');
    setShowShiftingModal(true);
    setShiftingDetails(details);
  };

  const togglePriceChangeRequest = (requestIdData) => {
    console.log('Price Change Request', requestIdData);
    setPriceChangeRequestsModal(!priceChangeRequestsModal);
    setRequestIdDataForPriceChange(requestIdData);
    setNewPrice('');
    setPriceChangeReason('');
    setInputReason('');
  };

  const handleDataFormChild = (basicInfo) => {
    setBasicInfoFromChild(basicInfo);
    console.log('basicInfoFromChild', basicInfo);
  };

  const openAddDoctorDetailModal = (details) => {
    console.log({ details }, 'open add Doctor Details modal');
    setAddDoctorDetailModalOpen(true);
  };

  const closeAddDoctorDetailModal = () => {
    setAddDoctorDetailModalOpen(false);
  };

  const toggleTooltip = (requestId) => {
    setTransferTooltipOpen((prevState) =>
      prevState === requestId ? -1 : requestId
    );
  };

  const editDoctorSlots = (doctorId) => {
    console.log('Check doctor Id here for editDoctorSlots', doctorId);
    if (!doctorId) {
      return;
    }
    dispatch(showDoctorSlotsEditModal(doctorId));
  };

  const closeDoctorSlotsEditModal = () => {
    dispatch(hideDoctorSlotsEditModal());
  };

  const closeFetchFollowUpModal = () => {
    setShowFetchFollowUpModal(false);
  };

  const getDoctorName = () => {
    const { requestDataForConfirm, shiftingDetails, currentRequestDetails } =
      state;
    return (
      requestDataForConfirm.doctorName ||
      shiftingDetails.doctorName ||
      currentRequestDetails.doctorName
    );
  };

  const closeRequestChatModal = () => {
    dispatch(hideInternalComments());
  };

  const ProcessingDate = styled(RegularText)`
    display: flex;
    text-align: center;
    align-items: center;
  `;
  const onSearchClick = () => {
    let notMobile = 0;
    let notEmail = 0;
    const mobileNumberValidationRegex = /^[6789]\d{9}$/;
    const emailValidationRegex =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    if (!mobileNumberValidationRegex.test(search)) {
      notMobile = 1;
    }
    if (!emailValidationRegex.test(search)) {
      notEmail = 1;
    }
    if (notEmail && notMobile && search) {
      toast.error(`Please enter a valid phone number/email`);
      return;
    }

    if (loading) {
      return;
    }
    getOfflineRequestData();
  };

  const setSegregationFilter = (sg) => {
    if (segregation === sg) {
      return;
    } else {
      setSegregation(sg);
    }
    let status = null;
    let currentStatus;
    let cols;
  };

  useEffect(() => {
    if (orderBy !== null && orderDir !== null) {
      getOfflineRequestData();
    }
    if (!showRequestDetails) {
      dispatch(updateRequestID(null));
    }
  }, [
    segregation,
    pageSize,
    page,
    orderBy,
    orderDir,
    statusFilter,
    tagsFilter,
    claimedByFilter,
    sponsorFilter,
    appointmentEndDate,
    appointmentStartDate,
    requestEndDate,
    requestStartDate,
    processingEndDate,
    patientName,
    doctorName,
    centerName,
    ackFilter,
    payoutFilter,
    onboardingStatusFilter,
    paymentCycleFilter,
    filterRequestId,
    stuckReasonFilter
  ]);

  useEffect(() => {
    return () => {
      debouncedSetFilterRequestId.cancel();
      debouncedSetPatientName.cancel();
      debouncedSetDoctorName.cancel();
      debouncedSetCenterName.cancel();
    };
  }, []);

  useEffect(() => {
    const getSponsors = async () => {
      try {
        const response = await fetchAllSponsors();
        const sponsorsList = response.data.map((sponsor) => ({
          value: sponsor.sponsorId,
          label: sponsor.sponsorName,
        }));
        console.log(sponsorsList, 'Response from server');
        setSponsorList(sponsorsList);
      } catch (error) {
        console.error('Error fetching sponsors:', error);
        setSponsorList([]);
      }
    };

    const getAgentStatus = async () => {
      try {
        const response = await getOPDAgentStatus();
        setOpsUserStatus(response.result);
        setGroupId(response.result.groupId);
      } catch (error) {
        console.error('Error fetching agent status:', error);
      }
    };

    getSponsors();
    getAgentStatus();
  }, []);

  // useEffect(()=>{
  //   if(opsUserStatus && opsUserStatus.opdCheckIn == 1 && segregation == 0){
  //     setClaimedByFilter(userId);
  //   }
  // },[opsUserStatus])

  console.log(opsUserStatus,'opsUserStatus');

  const changeStatusFilter = (value) => {
    console.log({ value }, 'Filter Inner Component');
    setStatusFilter(value);
    if(value=='unattempted'){
      setOrderDir('asc');
      setOrderBy('appointmentDate')
    }
  };

  // change processing date filters
  const changeProcessingDate = (req) => {
    console.log({ req });
      setChangeProcessingDateModal({
        isOpen: true,
        requestId: req.requestId,
        processingDate: req.processingDate,
      })
  };

 const closeProcessingDateModal = () => {
      setChangeProcessingDateModal({
        isOpen: false,
        requestId: null,
        processingDate: null,
      })
    getOfflineRequestData();
  };

  const changeTagsFilter = (value) => {
    console.log({ value }, 'Filter Inner Component');
    setTagsFilter(value);
  };

  const changeAckFitler = (value) => {
    console.log({ value }, 'Filter Inner Component');
    setAckFilter(value);
  };

  const handleSearchChange = (value) => {
    if (value) {
      setRequestStartDate(null);
      setRequestEndDate(null);
    }
  };
 
  const toggleActionsColumn = () => {
    // Toggle the state between true and false
    setToggleActions((prevState) => !prevState);
  };

  const openFetchFollowUpModal = (details) => {
    console.log({ details }, 'open fetch follow up modal');
    setShowFetchFollowUpModal(true);
    setFetchFollowUpDetails(details);
  };

  const onChangeOnboardingStatusFilter = (value) => {
    setOnboardingStatusFilter(value);
  };

  const onChangeNetworkSourceStatusFilter = (value) => {
    setPaymentCycleFilter(value);
  };
  const handleAppointmentDateChange = (date, dateString) => {
    console.log(date, dateString, 'DateRange----');
    setSelectedDateRange(date);
    setAppointmentStartDate(dateString[0]);
    setAppointmentEndDate(dateString[1]);
  };
  

  const handleRequestDateChange = (date, dateString) => {
    console.log(date, dateString, 'DateRangefsdvsd');
    setSelectedRequestDateRange(date);
    setRequestStartDate(dateString[0]);
    setRequestEndDate(dateString[1]);
  };

  const handleProcessingDateChange = (date, dateString) => {
    console.log(date, dateString, 'Date Range');
    setSelectedProcessingDateRange(date);
    setProcessingStartDate(dateString[0]);
    setProcessingEndDate(dateString[1]);
  };

  const handleChangeCheckbox = (e) => {
    if (e.target.checked) {
      setAllChecked([...allChecked, parseInt(e.target.value)]);
    } else {
      setAllChecked(allChecked.filter((item) => item != e.target.value));
      setSelectAllChecked(false);
    }
  };

  const handleAllChangeCheckbox = (e) => {
    if (e.target.checked) {
      setSelectAllChecked(!selectAllChecked);
      setAllChecked(dataSource.map((res) => res.requestId));
    } else {
      setSelectAllChecked(!selectAllChecked);
      setAllChecked([]);
    }
  };

  const handleChangeForCallReason = (event) => {
    const { value } = event.target;
    console.log(value, 'handleChangeForCallReason');
    setSelectedCallReason(value);
    setColorGreading(true);
    console.log('selectedCallReason', selectedCallReason);
  };

  const submitUserAcknowledgeCall = (requestId, selectedCallReason) => {
    if (!requestId) {
      return;
    }
    return callUserRequest(
      requestId,
      'userAcknowledge',
      selectedCallReason
    ).then(getAllOfflineRequests);
  };

  const confirmCloseModal = () => {
    setConfirmModalIsOpen(false);
  };

  const copyDetails = () => {
    navigator.clipboard.writeText(
      `
Patient name : ${basicInfoFromChild.patientName}
Patient phone : ${basicInfoFromChild.patientPhone}
Doctor : ${basicInfoFromChild.doctorName},${basicInfoFromChild.vertical}
Center name : ${basicInfoFromChild.centerName}
Center address : ${basicInfoFromChild.centerAddress}
Appointment date : ${basicInfoFromChild.appointmentDate}
`
    );
  };
  const cancelOpenModal = (requestId, priceApprovalStatus) => {
    if (priceApprovalStatus === 'Pending') {
      toast.error(
        'There is an active Price change request for this doctor. Please connect with network team. Until then you cannot cancel this appointment.'
      );
      return;
    }
    setCancelModalIsOpen(true);
    // setRequestId(requestId);
    dispatch(updateRequestID(requestId));
  };

  const cancelCloseModal = () => {
    setCancelModalIsOpen(false);
  };

  const setAckButtonConfirmModal = (requestId) => {
    setIsCallModalOpen(true);
    setConfirmModal({
      isOpen: true,
      header: 'Call User?',
      body: 'Are you sure you want to call user?',
      requestId,
      onSubmit: submitUserAcknowledgeCall,
      onClose: () => {
        setConfirmModal({ isOpen: false });
        setIsCallModalOpen(false);
        setSelectedCallReason(null);
      },
    });
  };

  const submitConfirmModalGen = () => {
    const { onSubmit, requestId } = confirmModal;
    return onSubmit(requestId, selectedCallReason).then(() => {
      setConfirmModal((prevState) => ({ ...prevState, isOpen: false }));
    });
  };

  const submitCancelModal = (
    reason,
    remarks,
    cancellationFee,
    subCancelReason
  ) => {
    if (!requestId || !reason) {
      console.error({ requestId, reason }, 'No request id present');
      toast.error('Select a reason to continue');
      return;
    }
    return cancelRequest(
      requestId,
      reason,
      remarks,
      cancellationFee,
      subCancelReason
    )
      .then((response) => {
        // Handle success response
        toast.success('Cancel Request Success');
      })
      .catch((err) => {
        // Error Handle
        toast.error('Cancel Request fail Please try again');
      })
      .finally(() => {
        // Handle Final (mainly for spinners)
      });
  };
  const submitConfirmModal = (
    dateKey,
    slot,
    slotType = 'fixed',
    startAppointmentTime,
    endAppointmentTime
  ) => {
    const timeRange =
      startAppointmentTime && endAppointmentTime && slotType === 'walk-in'
        ? `${startAppointmentTime}-${endAppointmentTime}`
        : null;
    if (!requestId || !slot || !dateKey) {
      console.error({ requestId, dateKey, slot }, 'Params missing');
      return;
    }
    if (
      slotType === 'walk-in' &&
      !(startAppointmentTime || endAppointmentTime)
    ) {
      console.error(
        { startAppointmentTime, endAppointmentTime },
        'Params missing'
      );
      return;
    }
    return confirmRequest(
      requestId,
      dateKey,
      slot,
      centerRequestStatus,
      slotType,
      timeRange
    )
      .then((response) => {
        // Handle success response
        toast.success('Confirm Request Success');
      })
      .catch((err) => {
        // Error Handle
        toast.error('Confirm Request fail Please try again');
      })
      .finally(() => {
        // Handle Final (mainly for spinners)
      });
  };

  const openClaimRequestButton = (requestId) => {
    setShowClaimRequestModal(true);
    setShowClaimRequestModalRequestId(requestId);
  };

  const handleOpdCheckInAndCheckOut = async () => {
    setCheckInCheckOutLoader(true);
    if(opsUserStatus.opdCheckIn){
      //will call api to check out ops user
      const response = await markOpsUserCheckOut();
      console.log(response,'response');
      if(response.message == 'success'){
        toast.success('Checked out Successfully.');
        setOpsUserStatus({...opsUserStatus,opdCheckIn : 0})
        toggleCheckInModal();
        setCheckInCheckOutLoader(false);
      }

    }else{
      //will call api to check in ops user
      const response = await markOpsUserCheckIn();
      console.log(response,'response');
      if(response.message == 'success'){
        toast.success('Checked In Successfully. New Requests have been assigned to you.');
        setOpsUserStatus({...opsUserStatus,opdCheckIn : 1})
        toggleCheckInModal();
        setCheckInCheckOutLoader(false);
      }
    }
  }

  const handleRidSearch = (value) => {
    setRequestEndDate(null);
    setRequestStartDate(null);
    setFilterRequestId(value);
  }
  const onClaimRequestModalClose = () => {
    setShowClaimRequestModal(false);
    getAllOfflineRequests();
  };

  const openSuggestions = (details) => {
    console.log({ details }, 'openSuggestions');
    setShowSuggestions(true);
    setSuggestionsDetails(details);
  };

  const closeSuggestions = () => {
    setShowSuggestions(false);
    setSuggestionsDetails(null);
  };

  const toggleShiftToReimbursementModal = (requestId) => {
    console.log('Shift to Reimbursement', requestId);
    setShiftReimbursementModalOpen(!shiftReimbursementModalOpen);
    setShiftToReimbursementReqId(requestId);
  };

  const shiftAppointmentToReimbursement = (requestId) => {
    setLoading(true);
    console.log(`Shifting to reimbursement ${requestId}`);
    shiftOfflineConsultationToReimbursement(requestId)
      .then((res) => {
        // Fetch consults
        if (res.message === 'success') {
          toast.success('Success');
          getAllOfflineRequests();
        } else {
          toast.error(res.errorMessage || 'Failed to Shift to reimbursement');
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.errorMessage || 'Failed to Shift to reimbursement');
      })
      .finally(() => {
        toggleShiftToReimbursementModal();
        setLoading(false);
      });
  };

  const toggleRequestReconfirmationModal = (requestId) => {
    console.log('toggleShiftToPendingModal', requestId);
    setRequestReconfirmationModalOpen(!requestReconfirmationModalOpen);
    setRequestReconfirmationRequestId(requestId);
  };

  const toggleCheckInModal = (requestId) => {
    console.log('toggleCheckInModal', requestId);
    setCheckInModalOpen(!checkInModalOpen);
    setRequestReconfirmationRequestId(requestId);
  }

  const requestReconfirmationForConsultation = (requestId) => {
    setLoading(true);
    console.log('requestReconfirmationForConsultation', requestId);
    return requestReconfirmationForOfflineConsult(requestId)
      .then((resp) => {
        if (resp.message === 'success') {
          toast.success('Successfully Requested For Reconfirmation');
          // this.onFetchData();
        } else {
          toast.error(resp.errorMessage);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error('Something went wrong');
      })
      .finally(() => {
        setLoading(false);
        toggleRequestReconfirmationModal();
      });
  };

  const showDetails = (requestId, requestDetails = {}) => {
    console.log('showDetails', requestId, requestDetails);
    if (!requestId) {
      return;
    }
    setCurrentRequestDetails(requestDetails);
    dispatch(showRequestModal(requestId, requestDetails));
  };
  const closeDetailModal = () => {
    console.log('closeDetailModal', requestId);
    setCurrentRequestDetails({});
    dispatch(hideRequestModal());
  };
  const bulkAssigneRequest = () => {
    setAddAssigneModalOpen(true);
    setAddAssigneFlag('addBulkAssigne');
  };

  const handleAssigneModal = () => {
    setAddAssigneModalOpen(false);
  };

  const handlePlaceRequestSubmit = (
    doctorId,
    centerId,
    userId,
    relativeId,
    dateKey,
    slotId,
    policyNumber,
    dependant
  ) => {
    return placeRequest(
      doctorId,
      centerId,
      userId,
      relativeId,
      dateKey,
      slotId,
      policyNumber,
      dependant
    ).then((response) => {
      if (response.message === 'success') {
        toast.success('Request Placed Success');
        return getAllOfflineRequests().then(() => true);
      } else {
        toast.error(response.errorMessage);
        return false;
      }
    });
  };

  const filterOption = (input, option) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  const tableStyle = {
    border: 'none',
    'font-size': '15px',
    // overflow: 'hidden',
    'background-color': '#FFFFFF',
    'font-weight': '500',
    'font-family': 'Montserrat',
    // width: '100vw',
  };

  const columns = useMemo(
    () => [
      {
        Header: () => (
          <div
            className="flex flex-row center-text"
            style={{
              // width: 50,
              justifyContent: 'center',
              textAlign: 'center',
              alignContent: 'center',
            }}
          >
            <Checkbox
              name="All"
              type="checkbox"
              placeholder="All"
              checked={selectAllChecked}
              onChange={handleAllChangeCheckbox}
            >
            </Checkbox>
          </div>
        ),
        filterable: false,
        width : 50,
        style: { alignContent: 'center', justifyContent: 'center' },
        accessor: 'All',
        Cell: (row) => (
          console.log('row', row.original),
          (
            <div className="flex flex-row justify-center">
              <Checkbox
                type="checkbox"
                value={row.original.requestId}
                checked={allChecked.includes(row.original.requestId)}
                onChange={handleChangeCheckbox}
              ></Checkbox>
            </div>
          )
        ),
      },
      {
        // Header: 'Request ID',
        // accessor: 'requestId',
        // style: { width: 700 },
        // // width: '126px',
        // height: 50,
        Header: () => (
          <div
            style={{
              justifyContent: 'center',
              textAlign: 'center',
              alignContent: 'center',
            }}
            className="flex flex-row center-text"
          >
            RID
          </div>
        ),
        // Filter: <RidSearch onChange={handleRidSearch}/>,
        // filter/:true,
        accessor: 'requestId',
        filterable: true,
        width: 90,
        style: {
          textAlign: 'center',
          fontWeight: '600',
          alignContent: 'center',
        },
        Cell: (row) => {
          // console.log('props offline', this.props);
          const {
            requestId,
            appointmentDate,
            tpaFailed,
            tpaCancelledAt,
            callSid,
            centerRequest,
          } = row.original;
          return (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                padding: '0.5rem',
              }}
            >
              <button
                style={{
                  backgroundColor: 'transparent',
                  color: '#714FFF',
                  border: 'none',
                  fontWeight: 'bold',
                }}
                onClick={() => showDetails(requestId, row.original)}
              >
                {requestId}
              </button>
            </div>
          );
        },
      },
      {
        Header: () => (
          <div
            className="flex flex-row"
            style={{
              justifyContent: 'center',
              textAlign: 'center',
            }}
          >
            Claimed By
          </div>
        ),
        accessor: 'claimedUserName',
        width: 115,
        style: {
          textAlign: 'center',
          fontWeight: '600',
          alignContent: 'center',
        },
        filterable: true,
        Filter: ({ filter, onChange }) => (
          <ClaimedByFilter
            onChange={onChange}
            // value={filter ? filter.value : ''}
            value={claimedByFilter ? claimedByFilter : filter ? filter.value : ''}
          />
        ),
        Cell: (row) => {
          const { claimedUserName } = row.original;
          if (claimedUserName) {
            return claimedUserName;
          } else {
            return '';
          }
        },
      },
      {
        Header: () => (
          <div
            className="flex flex-row"
            style={{
              justifyContent: 'center',
              textAlign: 'center',
            }}
          >
            Status
          </div>
        ),
        width: 126,
        accessor: 'status',
        style: {
          textAlign: 'center',
          fontWeight: '600',
          alignContent: 'center',
        },
        filterable: true,
        // canFilter: true,
        Filter: (props) => (
          <StatusFilter
            onChangeValue={changeStatusFilter}
            value={statusFilter}
            segregation={segregation}
            // {...props}
          />
        ),
        Cell: (row) => {
          const reasonMap = {
            'I was not informed about the rescheduling': 'User was uninformed',
            'I am not comfortable with the timeslot': 'Time issue',
            'I requested for a different doctor': 'Doctor issue',
            'I wanted an appointment for a different date': 'Date issue',
            'I am not comfortable with the consultation fee':
              'Consultation fee',
            'I requested for a different centre': 'Centre issue',
          };
          const {
            status,
            rescheduledTimeDiff,
            subCancelReason,
            userConfirmed,
            hospitalRescheduleId,
            concernReason,
            priceApprovalStatus,
            opsRescheduledRequest,
          } = row.original;
          console.log(status, 'status123', subCancelReason);
          const shortReason =
            reasonMap[concernReason] || concernReason
              ? ` - (User Escalated) ${
                  reasonMap[concernReason] || concernReason
                }`
              : '';
              if (
                priceApprovalStatus === 'Pending' &&
                (status === 'Apt. Pending' || status === 'Confirmed')
              ) {
                return (
                  <StatusPendingContainer>
                    Price Approval Pending
                  </StatusPendingContainer>
                );
              } else if (status === 'Rx Approved') {
                return (
                  <StatusCompleteContainer>
                    {status}
                  </StatusCompleteContainer>
                );
              }else if (
                hospitalRescheduleId &&
                parseInt(rescheduledTimeDiff) <= 2 &&
                !userConfirmed &&
                concernReason
              ) {
                return (
                  <StatusPendingContainer>
                    User Escalated{shortReason}
                  </StatusPendingContainer>
                );
              } else if (
                hospitalRescheduleId &&
                parseInt(rescheduledTimeDiff) <= 2 &&
                !userConfirmed &&
                opsRescheduledRequest
              ) {
                return (
                  <div>
                    Confirmation Awaited
                  </div>
                );
              } else if (
                hospitalRescheduleId &&
                parseInt(rescheduledTimeDiff) <= 2 &&
                !userConfirmed
              ) {
                return (
                  <StatusPendingContainer>
                    Appt. Rescheduled
                  </StatusPendingContainer>
                );
              } else if (
                parseInt(rescheduledTimeDiff) &&
                parseInt(rescheduledTimeDiff) > 2 &&
                !userConfirmed
              ) {
                return <StatusPendingContainer>Call User</StatusPendingContainer>;
              } else if (
                status === 'Payment Pending' ||
                status === 'Rx Pending'
              ) {
                return <StatusPendingContainer>{status}</StatusPendingContainer>;
              } else if (status === 'Rx Uploaded') {
                return (
                  <StatusCompleteContainer>{status}</StatusCompleteContainer>
                );
              } else if (status === 'User Cancelled' && subCancelReason) {
                return (
                  <>
                    {status} ({subCancelReason}){shortReason}
                  </>
                );
              }
          return `${status}${shortReason}`;
        },
      },
      {
        Header: () => (
          <div
            className="flex flex-row"
            style={{
              justifyContent: 'center',
              textAlign: 'center',
            }}
          >
            Tags
          </div>
        ),
        width: 140,
        accessor: 'none',
        style: {
          textAlign: 'center',
          fontWeight: '600',
          alignContent: 'center',
        },
        filterable: true,
        Cell: (row) => {
          const {
            isVipUser,
            escalatedRequest,
            emergencyCallMade,
            dueFollowUpCount,
            prebookedAppointment,
          } = row.original;
          console.log(row.original, 'row.original---');
          if (isVipUser || escalatedRequest || emergencyCallMade || dueFollowUpCount || prebookedAppointment) {
            return (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  flexWrap: 'wrap',
                }}
              >
                {isVipUser ? (
                <label
                  style={{
                    borderRadius: '10px',
                    padding: '5px',
                    color: 'white',
                    border: '2px solid #ECB800',
                    backgroundColor: '#0049C6',
                    fontSize: '12px',
                    width: 'fit-content',
                  }}
                >
                  VIP User
                </label>): null}
              {escalatedRequest ? (
                <label
                  style={{
                    borderRadius: '10px',
                    padding: '7px 5px',
                    backgroundColor: '#FF906A',
                    color: 'white',
                    fontSize: '12px',
                    width: 'fit-content',
                  }}
                >
                  Escalated Cases
                </label>) : null}
              {emergencyCallMade ? (
                <label
                  style={{
                    borderRadius: '10px',
                      padding: '5px',
                      backgroundColor: '#DC3545',
                      color: 'white',
                      fontSize: '12px',
                      width: 'fit-content',
                    }}
                  >
                    Emergency Call
                  </label>) : null}
                {dueFollowUpCount ?
                  (
                    <>
                    <p
                      className=""
                      id={`fetchfollowUpButton-${row.original.requestId}`}
                      onClick={() => openFetchFollowUpModal(row.original)}
                      style={{ cursor: 'pointer', color: 'blue' }}
                      onMouseOver={(e) =>
                        (e.currentTarget.style.textDecoration = 'underline')
                      }
                      onMouseOut={(e) =>
                        (e.currentTarget.style.textDecoration = 'none')
                      }
                    >
                      <label
                        style={{
                          borderRadius: '10px',
                          padding: '5px',
                          backgroundColor: '#ECB800',
                          color: 'white',
                          fontSize: '12px',
                          width: 'fit-content',
                        }}
                      >
                        Follow Up Due ({dueFollowUpCount})
                      </label>
                    </p>
                    <UncontrolledTooltip target={`fetchfollowUpButton-${row.original.requestId}`}>
                      Show Follow Ups
                    </UncontrolledTooltip>
                    </>
                  )
                  : null}
                {prebookedAppointment ? (
                  <label
                    style={{
                      borderRadius: '10px',
                      padding: '5px',
                      backgroundColor: '#00B479',
                      color: 'white',
                      fontSize: '12px',
                      width: 'fit-content',
                    }}>
                  Selfbooked
                </label>): null}
              </div>
            );
          } 
          return <div></div>;
        },
        Filter: ({ props }) => (
          <TagsFilter value={tagsFilter} onChangeTags={changeTagsFilter} />
        ),
      },

      {
        Header: () => (
          <div
            className="flex flex-row"
            style={{
              justifyContent: 'center',
              textAlign: 'center',
            }}
          >
            Sponsor
          </div>
        ),

        width: 126,
        style: {
          textAlign: 'center',
          fontWeight: '600',
          alignContent: 'center',
        },
        accessor: 'sponsorName',
        Filter: () => (
          <Select
          allowClear
            showSearch
            options={sponsorList}
            onChange={handleSponsor}
            filterOption={filterOption}
            placeholder="All"
            className="sposor-filter"
            styles={{
              height: '42px',
              // Fixes the overlapping problem of the component
              menu: (provided) => ({
                ...provided,
                zIndex: 9999,
                width: '170px',
              }),
            }}
            menuPortalTarget={document.body}
            menuPosition={'fixed'}
          />
        ),
      },
      {
        Header: () => (
          <div
            className="flex flex-row"
            style={{
              justifyContent: 'center',
              textAlign: 'center',
            }}
          >
            App. Date
          </div>
        ),
        width: 90,
        style: {
          textAlign: 'center',
          fontWeight: '600',
          alignContent: 'center',
        },
        accessor: 'appointmentDate',
        Filter: () => (
          <RangePicker
            value={selectedDateRange}
            showTime={{ format: 'hh:mm a' }}
            style={{
              borderRadius: '10px',
            }}
            onChange={handleAppointmentDateChange}
          />
        ),
      },

      {
        Header: () => (
          <div
            className="flex flex-row"
            style={{
              justifyContent: 'center',
              textAlign: 'center',
            }}
          >
            Req. Date
          </div>
        ),
        width: 90,
        style: {
          textAlign: 'center',
          fontWeight: '600',
          alignContent: 'center',
        },
        accessor: 'requestDate',
        Filter: () => (
          <RangePicker
            value={selectedRequestDateRange}
            showTime={{ format: 'hh:mm a' }}
            style={{
              borderRadius: '10px',
            }}
            onChange={handleRequestDateChange}
          />
        ),
      },
      {
        Header: () => (
          <div
            className="flex flex-row"
            style={{
              justifyContent: 'center',
              textAlign: 'center',
            }}
          >
            Proc. Date
          </div>
        ),
        width: 90,
        style: {
          textAlign: 'center',
          fontWeight: '600',
          alignContent: 'center',
        },
        accessor: 'processingDate',
        Filter: () => (
          <RangePicker
            value={selectedProcessingDateRange}
            style={{
              borderRadius: '10px',
            }}
            onChange={handleProcessingDateChange}
          />
        ),
        Cell: (row) => {
          return (
            <ProcessingDate style={{
              alignContent: 'center',
              textAlign: 'center',
              display: 'block',
            }} color={row.original.processingDateColor} 
            onClick={() => changeProcessingDate(row.original)}
            >
              {moment(row.original.processingDate * 1000).format(
                'DD MMM h:mm A'
              )}
            </ProcessingDate>
          );
        },
      },
      {
        Header: () => (
          <div
            className="flex flex-row"
            style={{
              justifyContent: 'center',
              textAlign: 'center',
            }}
          >
            Patient Name
          </div>
        ),
        width: 140,
        style: {
          textAlign: 'center',
          fontWeight: '600',
          alignContent: 'center',
        },
        accessor: 'patientName',
        filterable: true,
        Filter: <RidSearch onChange={handlePatientNameChange} />,
      },

      {
        Header: () => (
          <div
            className="flex flex-row"
            style={{
              justifyContent: 'center',
              textAlign: 'center',
            }}
          >
            Doctor Name
          </div>
        ),
        width: 136,
        style: {
          textAlign: 'center',
          fontWeight: '600',
          alignContent: 'center',
        },
        accessor: 'doctorName',
        filterable: true,
        Filter: ({ filter, onChange }) => (
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <RidSearch filter={filter} onChange={handleDoctorNameChange} />
            <OnboardingStatusFilter
              onChange={onChangeOnboardingStatusFilter}
              value={onboardingStatusFilter}
            />
          </div>
        ),
      },
      {
        Header: () => (
          <div
            className="flex flex-row"
            style={{
              justifyContent: 'center',
              textAlign: 'center',
            }}
          >
            Center
          </div>
        ),
        width: 200,
        style: {
          textAlign: 'center',
          fontWeight: '600',
          alignContent: 'center',
        },
        accessor: 'networkCenterName',
        filterable: true,
        Filter: ({ filter, onChange }) => (
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <RidSearch filter={filter} onChange={handleCenterNameChange} />
            <NetworkSourceFilter
              onChange={onChangeNetworkSourceStatusFilter}
              value={paymentCycleFilter}
            />
          </div>
        ),
        Cell: (row) => {
          const {
            networkCenterName,
            networkCenterMappingNetworkId,
            networkCenterType,
          } = row.original;
          return (
            <>
              {`${networkCenterName} (${networkCenterType || ''})`}
              <br />
              {networkCenterMappingNetworkId ? <NetworkCenterIcon /> : <></>}
            </>
          );
        },
      },
      {
        Header: () => (
          <div
            className="flex flex-row"
            style={{
              justifyContent: 'center',
              textAlign: 'center',
            }}
          >
            Ack. Call
          </div>
        ),
        width: 200,
        style: {
          textAlign: 'center',
          fontWeight: '600',
          alignContent: 'center',
        },
        filterable: true,
        accessor: 'acknowledgementCall',
        Cell: (row) => {
          const {
            requestId,
            userPhone,
            acknowledgementCallCount,
            acknowledgementCallDuration,
          } = row.original;
          let status = 'Pending';
          if (
            acknowledgementCallDuration &&
            acknowledgementCallDuration !== '0s'
          ) {
            status = `Yes (${acknowledgementCallDuration}, ${acknowledgementCallCount})`;
            return (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <StatusCompleteContainer>{status}</StatusCompleteContainer>
                <div>
                  {userPhone && (
                    <CallingButton
                      onSubmit={() => setAckButtonConfirmModal(requestId)}
                    />
                  )}
                </div>
              </div>
            );
          } else if (parseInt(acknowledgementCallCount) !== 0) {
            status = `No Answer (${acknowledgementCallCount})`;
            return (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <StatusPendingContainer>{status}</StatusPendingContainer>
                <div>
                  {userPhone && (
                    <CallingButton
                      onSubmit={() => setAckButtonConfirmModal(requestId)}
                    />
                  )}
                </div>
              </div>
            );
          }
          return (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <StatusDangerContainer>{status}</StatusDangerContainer>
              <div>
                {userPhone && (
                  <CallingButton
                    onSubmit={() => setAckButtonConfirmModal(requestId)}
                  />
                )}
              </div>
            </div>
          );
        },
        Filter: (props) => {
          return <AckFilter {...props} onChange={changeAckFitler} />;
        },
      },
      {
        Header: () => (
          <div
            className="flex flex-row"
            style={{
              justifyContent: 'center',
              textAlign: 'center',
            }}
          >
            Payout
          </div>
        ),
        width: 140,
        style: {
          textAlign: 'center',
          fontWeight: '600',
          alignContent: 'center',
        },
        filterable: true,
        accessor: 'payoutStatus',
        Filter: (props) => (
          <PayoutFilter onChangeValue={setPayoutFilter} {...props} />
        ),
      },
      {
        Header: () => (
          <div
            className="flex flex-row"
            style={{
              justifyContent: 'center',
              textAlign: 'center',
            }}
          >
            Stuck Reason
          </div>
        ),

        width: 200,
        style: {
          textAlign: 'center',
          fontWeight: '600',
          alignContent: 'center',
        },
        accessor: 'stuckReason',
        Filter: () => (
          <Select
            showSearch
            options={reasonOptions}
            onChange={handleStuckReason}
            filterOption={filterOption}
            placeholder="All"
            className="stuck-reason"
            styles={{
              height: '42px',
              // Fixes the overlapping problem of the component
              menu: (provided) => ({
                ...provided,
                zIndex: 9999,
                width: '150px',
              }),
            }}
            menuPortalTarget={document.body}
            menuPosition={'fixed'}
          />
        ),
      },
      {
        Header: () => (
          <div
            className="flex flex-row"
            style={{
              justifyContent: 'center',
              textAlign: 'center',
            }}
          >
            Comments
          </div>
        ),
        width: 140,
        style: {
          textAlign: 'center',
          fontWeight: '600',
          alignContent: 'center',
        },
        filterable: false,
        accessor: 'countComments',
      },
      {
        Header: () => (
          <div
            // className="flex flex-row"
            style={{
              justifyContent: 'center',
              textAlign: 'center',
            }}
          >
            Center Status
          </div>
        ),
        width: 160,
        style: {
          textAlign: 'center',
          fontWeight: '600',
          alignContent: 'center',
        },
        filterable: false,
        Cell: (row) => {
          const { centerStatus } = row.original;
          if (centerStatus) {
            return 'empanelled';
          } else {
            return 'depanelled';
          }
        },
      },
      {
        Header: () => (
          <div
            className="flex flex-row"
            style={{
              justifyContent: 'center',
              textAlign: 'center',
            }}
          >
            Reschedule
          </div>
        ),
        width: 123,
        style: {
          textAlign: 'center',
          fontWeight: '600',
          alignContent: 'center',
        },
        filterable: false,
        accessor: 'rescheduleStatus',
      },
      ...(toggleActions
        ? [
            {
              Header: 'Actions',
              numeric: false,
              disablePadding: false,
              filterable: false,
              accessor: 'actions',
              width: 195,
              style: {
                position: 'sticky',
                right: 0,
                top: 0,
                background: 'white',
                textAlign: 'center',
                zIndex: 2,
                alignContent: 'center',
                boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.1)',
              },
              headerStyle: {
                overflow: 'visible',
                position: 'sticky',
                right: 0,
                background: 'white',
                textAlign: 'center',
                zIndex: 2,
              },
              Cell: (row) => (
                <div
                  style={{
                    position: 'sticky',
                    right: 0,
                    background: 'white',
                    textAlign: 'center',
                    zIndex: 2,
                  }}
                >
                  <div className="flex flex-row justify-center">
                    <Actions
                      featureA={true}
                      tagsFilter={tagsFilter}
                      statusFilter={statusFilter}
                      requestId={row.original.requestId}
                      priceApprovalStatus={row.original.priceApprovalStatus}
                      onAction={onAction}
                      editDoctorSlots={editDoctorSlots}
                      requestData={row.original}
                      getOfflineRequestData={getOfflineRequestData}
                    />
                  </div>
                </div>
              ),
            },
          ]
        : []),
     
    ],
    [segregation, sponsorList, isOpen, openActionDropDown, allChecked, preBooked, statusFilter, tagsFilter, toggleActions, selectedDateRange, selectedRequestDateRange,selectedProcessingDateRange,claimedByFilter, filterRequestId]
  );
  // useEffect(() => {
  //   getOfflineRequestData();
  // }, []);

  return (
    <>
      <div
        style={{
          backgroundColor: '#F2F1F6',
          width: '100%',
        }}
      >
        <div>
          <NavBar />
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'column',
            marginTop: '72px',
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: '0px 20px',
              gap: '30px',
            }}
          >
            <button
              style={{
                background: '#A5A6BB',
                // borderColor: '#714FFF',
                margin: '0px',
                borderRadius: '5px',
                border: '0px',
                color: 'white',
                fontSize: '14px',
                fontWeight : '600',
                fontFamily: 'Inter, sans-serif',
                justifyContent: 'flex-start',
                padding: '12px 12px',
              }}
              onClick={bulkAssigneRequest}
            >
              Assign Request
            </button>
            <div>
              <PlaceRequest
                submit={handlePlaceRequestSubmit}
                onComplete={onAction}
              />
            </div>
            <div
              style={{
                // flexGrow: 2,
                display: 'flex', // Add this line
                alignItems: 'center',
                flex: '1',
                border: '1px solid #CFC8FF',
                borderRadius: '8px',
                backgroundColor: '#FFFFFF',
                padding: '2px',
                // flexBasis: '30%',
              }}
            >
              <img
                style={{
                  padding: '10px',
                }}
                src={SearchIcon}
                alt="searchicon"
              />
              <input
                style={{
                  flex: '1',
                  border: 'none',
                  // height: '36px',
                  boxShadow: 'none',
                  fontFamily: 'Inter, sans-serif',
                  padding: '11px',
                }}
                type="text"
                value={search}
                onChange={(e) => handleChange(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    onSearchClick();
                  }
                }}
                placeholder="Search with a Phone Number or Name"
              />
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-start',
                backgroundColor: '#FFFFFF',
                borderRadius: '8px',
                border: '1px solid #CFC8FF',
                // width: '30%',
                textAlign: 'center',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-start',

                  fontFamily: '"Arial", sans-serif',
                }}
              >
                {(loading || countLoader) ?  <LoadingComponent/> :  <div
                  style={{
                    width: 'max-content',
                    margin: 'auto',
                    padding: '5px 10px 5px 10px',
                  }}
                >
                  <span
                    style={{
                      fontSize: '24px',
                      marginRight: '5px',
                      fontWeight: 'bold',
                      color: '#714FFF',
                    }}
                  >
                    {totalHits}
                  </span>
                  <span
                    style={{
                      color: '#A5A6BB',
                      fontSize: '14px',
                    }}
                  >
                    Total Cases
                  </span>
                </div> }
              </div>
            </div>
            {opsUserStatus && groupId && <button style={{
                background: '#714FFF',
                // borderColor: '#714FFF',
                margin: '0px',
                borderRadius: '5px',
                border: '0px',
                color: 'white',
                // height: '48px',
                padding: '12px 12px',
                fontSize: '14px',
                fontWeight : '600',
                fontFamily: 'Inter, sans-serif',
                justifyContent: 'flex-start',
              }} 
              onClick={()=> setCheckInModalOpen(true)}
              >
                {opsUserStatus && opsUserStatus.opdCheckIn ? 'Check Out' : 'Check In'}
            </button>}
            <div
              style={{
                display: 'flex',
              }}
            >
              <DownloadCsv params={params} />
            </div>
            <button style={{
                background: '#714FFF',
                // borderColor: '#714FFF',
                margin: '0px',
                borderRadius: '5px',
                border: '0px',
                color: 'white',
                // height: '48px',
                padding: '12px 12px',
                fontSize: '14px',
                fontWeight : '600',
                fontFamily: 'Inter, sans-serif',
                justifyContent: 'flex-start',
              }} 
              onClick={openAddDoctorDetailModal}>
              Add Doctor
            </button>
            {/* <button style={{
                background: '#714FFF',
                // borderColor: '#714FFF',
                margin: '0px',
                borderRadius: '5px',
                border: '0px',
                color: 'white',
                // height: '48px',
                fontFamily: 'Inter, sans-serif',
                justifyContent: 'flex-start',
                padding: '12px 24px 12px 24px',
              }} 
              onClick={toggleActionsColumn}>
              {toggleActions ? 'Hide Actions' : 'Show Actions'}
            </button> */}
            <img
              onClick={toggleActionsColumn}
              src={toggleActions ? UnhideActions : ShowActions}
              alt={toggleActions ? 'Hide Actions' : 'Show Actions'}
              id='toggleActions'
            />
            <UncontrolledTooltip target={'toggleActions'}>
              {toggleActions ? 'Hide Actions' : 'Show Actions'}
            </UncontrolledTooltip> 
          </div>
          <div
            className="mt-4"
            style={{
              display: 'flex',
              padding: '0px 20px',
              justifyContent: 'space-between',
              width: '100%',
              gap: '5px',
            }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-start',
              }}
            >
              <button
                style={{
                  borderRadius: '20px 20px 0px 0px',
                  whiteSpace: 'nowrap',
                  fontFamily: 'Inter, sans-serif',
                  cursor: 'pointer',
                  border: 'none',
                  backgroundColor: buttonActive === null ? '#FFFFFF' : '#A5A6BB',
                  color: buttonActive === null ? '#714FFF' : '#FFFFFF',
                  padding: '8px 16px 8px',
                  margin: '0px',
                  fontSize: '14px',
                fontWeight : '600',
                }}
                onClick={() => {setSegregationFilter(null); setButtonActive(null); setStatusFilter(null);}}
                className="myButton"
              >
                All
              </button>
              <button
                style={{
                  borderRadius: '20px 20px 0px 0px',
                  whiteSpace: 'nowrap',
                  fontFamily: 'Inter, sans-serif',
                  cursor: 'pointer',
                  border: 'none',
                  backgroundColor: buttonActive === 'pending' ? '#FFFFFF' : '#A5A6BB',
                  color: buttonActive === 'pending' ? '#714FFF' : '#FFFFFF',
                  padding: '8px 16px 8px',
                  margin: '0px',
                  fontSize: '14px',
                fontWeight : '600',
                }}
                onClick={() => {setSegregationFilter(0); setButtonActive('pending'); setStatusFilter(null);}}
                className="myButton"
              >
                Pending
              </button>
              <button
                style={{
                  borderRadius: '20px 20px 0px 0px',
                  whiteSpace: 'nowrap',
                  fontFamily: 'Inter, sans-serif',
                  border: 'none',
                  backgroundColor: buttonActive === 'processing' ? '#FFFFFF' : '#A5A6BB',
                  color: buttonActive === 'processing' ? '#714FFF' : '#FFFFFF',
                  padding: '8px 16px 8px 16px',
                  margin: '0px',
                  fontSize: '14px',
                fontWeight : '600',
                }}
                onClick={() => {setSegregationFilter(7); setButtonActive('processing'); setStatusFilter(null);}}
                className="myButton"
              >
                Processing
              </button>
              {/* <div>
              <Button
                style={{
                  backgroundColor: '#A5A6BB',
                  borderColor: '#A5A6BB',
                  borderRadius: '25px',
                  margin: '0 10px',
                  height: '36px',
                  whiteSpace: 'nowrap',
                  fontFamily: 'Inter, sans-serif',
                  width: '',
                }}
                onClick={() => setSegregationFilter(0)}
              >
                Schedule Request
              </Button>
            </div> */}
              <button
                style={{
                  borderRadius: '20px 20px 0px 0px',

                  whiteSpace: 'nowrap',
                  fontFamily: 'Inter, sans-serif',
                  fontSize: '14px',
                fontWeight : '600',
                  border: 'none',
                  backgroundColor: buttonActive === 'pc' ? '#FFFFFF' : '#A5A6BB',
                  color: buttonActive === 'pc'? '#714FFF' : '#FFFFFF',
                  padding: '8px 16px 8px 16px',
                  margin: '0px',
                }}
                onClick={() => {setSegregationFilter(6); setButtonActive('pc'); setStatusFilter(null);}}
                className="myButton"
              >
                Pending Callbacks
              </button>
              <button
                style={{
                  borderRadius: '20px 20px 0px 0px',
                  color: '#FFFFFF',
                  whiteSpace: 'nowrap',
                  fontFamily: 'Inter, sans-serif',
                  border: 'none',
                  backgroundColor: buttonActive === 'confirmRequests' ? '#FFFFFF' : '#A5A6BB',
                  color: buttonActive === 'confirmRequests' ? '#714FFF' : '#FFFFFF',
                  padding: '8px 16px 8px 16px',
                  margin: '0px',
                  fontSize: '14px',
                fontWeight : '600',
                }}
                onClick={() => {setSegregationFilter(1); setButtonActive('confirmRequests'); setStatusFilter(null);}}
                className="myButton"
              >
                Confirmed Requests
              </button>
              <button
                style={{
                  borderRadius: '20px 20px 0px 0px',
                  fontSize: '14px',
                fontWeight : '600',
                  whiteSpace: 'nowrap',
                  fontFamily: 'Inter, sans-serif',

                  border: 'none',
                  backgroundColor: buttonActive === 'emergency' ? '#FFFFFF' : '#A5A6BB',
                  color: buttonActive === 'emergency' ? '#714FFF' : '#FFFFFF',
                  padding: '8px 16px 8px 16px',
                  margin: '0px',
                }}
                onClick={() => {setSegregationFilter(8); setButtonActive('emergency'); setStatusFilter(null);}}
                className="myButton"
              >
                Emergency Calls
              </button>
              <button
                style={{
                  borderRadius: '20px 20px 0px 0px',
                  fontSize: '14px',
                fontWeight : '600',
                  whiteSpace: 'nowrap',
                  fontFamily: 'Inter, sans-serif',

                  border: 'none',
                  backgroundColor: buttonActive === 'reconf' ? '#FFFFFF' : '#A5A6BB',
                  color: buttonActive === 'reconf' ? '#714FFF' : '#FFFFFF',
                  padding: '8px 16px 8px 16px',
                  margin: '0px',
                }}
                onClick={() => {setSegregationFilter(4); setButtonActive('reconf'); setStatusFilter(null);}}
                className="myButton"
              >
                Pending Reconf
              </button>
              <button
                style={{
                  borderRadius: '20px 20px 0px 0px',
                  color: '#FFFFFF',
                  whiteSpace: 'nowrap',
                  fontFamily: 'Inter, sans-serif',
                  border: 'none',
                  backgroundColor: buttonActive === 'delayed' ? '#FFFFFF' : '#A5A6BB',
                  color: buttonActive === 'delayed' ? '#714FFF' : '#FFFFFF',
                  padding: '8px 16px 8px 16px',
                  margin: '0px',
                  fontSize: '14px',
                fontWeight : '600',
                }}
                onClick={() => {setSegregationFilter(10); setButtonActive('delayed'); setStatusFilter(null);setOrderDir('asc');
                setOrderBy('appointmentDate');}}
                className="myButton"
              >
                Delayed Requests
              </button>
              <button
                style={{
                  borderRadius: '20px 20px 0px 0px',
                  color: '#FFFFFF',
                  whiteSpace: 'nowrap',
                  fontFamily: 'Inter, sans-serif',
                  border: 'none',
                  backgroundColor: buttonActive === 'prebooked_appointment' ? '#FFFFFF' : '#A5A6BB',
                  color: buttonActive === 'prebooked_appointment' ? '#714FFF' : '#FFFFFF',
                  padding: '8px 16px 8px 16px',
                  margin: '0px',
                  fontSize: '14px',
                  fontWeight: '600',
                }}
                onClick={() => {
                  setPreBooked(true); // Set preBooked to true on click
                  // setStatusFilter('prebooked_appointment'); // Set statusFilter to "prebooked_appointment"
                  setSegregation(14)
                  setButtonActive('prebooked_appointment');
                  setSegregationFilter(null);
                  console.log('prebooked', statusFilter);
                }}
                className="myButton"
              >
                Selfbooked Appointments
              </button>
            </div>

            <div
              style={{
                display: 'flex',
                gap: '8px',
                justifyContent: 'flex-end',
              }}
            >
              {isFilterApplied && (
                <div>
                  <button
                    style={{
                      borderRadius: '10px',
                      whiteSpace: 'nowrap',
                      fontFamily: 'Inter, sans-serif',
                      cursor: 'pointer',
                      border: '1px solid #FC6D3B',
                      backgroundColor: '#FFFFFF',
                      color: '#FC6D3B',
                      margin: '0px',
                      fontSize: '14px',
                fontWeight : '600',
                    }}
                    onClick={clearFilter}
                  >
                    Clear Filters
                  </button>
                </div>
              )}
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            {/* <div
              style={{
                display: 'flex',
                justifyContent: 'flex-start',
                padding: '0px 20px',
              }}
            ></div> */}
          </div>
          <ReactTable
            columns={columns}
            manual
            data={dataSource}
            loading={loading}
            showPaginationTop={true}
            style={tableStyle}
            showPaginationBottom={false}
            pages={pages}
            page={page}
            filterable
            pageSize={pageSize}
            selectRow={selectRow}
            className="-striped -highlight revamp-table"
            // onFetchData={() => onFetchData}
            onFetchData={(state, instance) => {
              filterData(state);
            }}
            pageSizeOptions={[5, 10, 20, 25, 50, 100, 200, 500, 700, 1000, 10000]}
            PaginationComponent={(props) => (
              <Pagination
                allChecked={allChecked}
                getAllOfflineRequests={getAllOfflineRequests}
                params={params}
                {...props}
                headerComponent={''}
              />
            )}
            SubComponent={(row) => (
              <SubComponent
                requestId={row.original.requestId}
                userId={row.original.userId}
              />
            )}
            defaultSorted={[
            {
              id: 'appointmentDate',
              asc: (segregation == 0 || statusFilter == 'unattempted') ? true : false,
              desc : (segregation == 0 || statusFilter == 'unattempted') ? false : true,
            },
            {
              id: "requestId",
              desc: 'true',
            }
          ]}
          />
        </div>

        {/* <div>
          <OfflineModalConfirm
            centered
            modalIsOpen={confirmModalIsOpen}
            openModal={confirmOpenModal}
            requestDataForConfirm={requestDataForConfirm}
            closeModal={confirmCloseModal}
            submitModal={submitConfirmModal}
            onComplete={onAction}
            showEditDoctorSlotsModal={editDoctorSlots}
            showEditDoctorSlots={showEditDoctorSlots}
            doctorId={doctorId}
            requestId={requestId}
          />
        </div> */}
        {/* <div>
          <OfflineModalCancel
            centered
            modalIsOpen={cancelModalIsOpen}
            openModal={cancelOpenModal}
            closeModal={cancelCloseModal}
            submitModal={submitCancelModal}
            onComplete={onAction}
            requestId={requestId}
          />
        </div> */}

        <div>
          {shiftingDetails && (
            <RescheduleAppointMentRequest
              isOpen={showShiftingModal}
              closeShiftingModal={() => setShowShiftingModal(false)}
              shiftingDetails={shiftingDetails}
              showEditDoctorSlotsModal={editDoctorSlots}
              fetchTableData={getOfflineRequestData}
              showEditDoctorSlots={showEditDoctorSlots}
            />
          )}
        </div>

        <div>
          {addDoctorDetailModalOpen && (
            <AddDoctorDetailModal
              centered
              isOpen={addDoctorDetailModalOpen}
              closeAddDoctorDetailModal={closeAddDoctorDetailModal}
              getOfflineRequestData={getOfflineRequestData}
              onComplete={onAction}
              followUpDetails={followUpDetails}
            />
          )}
        </div>
        <div>
          <ClaimRequestModal
            onClose={onClaimRequestModalClose}
            isOpen={showClaimRequestModal}
            requestId={showClaimRequestModalRequestId}
          />
        </div>
        <div>
        {showFetchFollowUpModal && (
        <FetchFollowUpModal
          isOpen={showFetchFollowUpModal}
          closeFetchFollowUpModal={closeFetchFollowUpModal}
          fetchFollowUpDetails={fetchFollowUpDetails}
          onComplete={onAction}
        />
      )}
        </div>
        <div>
          <SuggestionsDialog
            suggestionsDetails={suggestionsDetails}
            isOpen={showSuggestions}
            closeSuggestions={closeSuggestions}
          />
        </div>
        <div>
          <ConfirmModal
            modalHeader={confirmModal.header}
            onClose={confirmModal.onClose}
            isOpen={confirmModal.isOpen}
            onSubmit={submitConfirmModalGen}
            loading={confirmModal.buttonLoading}
            isCallModalOpen={isCallModalOpen}
            callReasonOptions={callReasonOptions}
            handleChange={handleChangeForCallReason}
            selectedValue={selectedCallReason}
          >
            {confirmModal.body}
          </ConfirmModal>
        </div>
        {addAssigneModalOpen && (
          <AddAssigneModal
            isModalOpen={addAssigneModalOpen}
            closeAssigneModal={handleAssigneModal}
            // requestId={addAssigneReqId}
            allCheckedReqIds={allChecked}
            getAllOfflineRequests={getOfflineRequestData}
            addAssigneFlag={addAssigneFlag}
          />
        )}

        <ChangeProcessingDateModal
          requestId={changeProcessingDateModal.requestId}
          processingDate={changeProcessingDateModal.processingDate}
          isOpen={changeProcessingDateModal.isOpen}
          closeProcessingDateModal={closeProcessingDateModal}
        />

        <div>
          <Modal
            isOpen={showRequestDetails}
            toggle={closeDetailModal}
            size="lg"
            className="rtsp-modal-big"
          >
            <ModalHeader
              toggle={closeDetailModal}
              className="revamp-modal-header"
            >
              {`Request Details (${requestId})`}{' '}
              <Button
                style={{
                  borderRadius: '8px',
                  border: `1px solid #714FFF`,
                  background: '#FFFFFF',
                  height: '25px',
                  width: '122px',
                  color: '#714FFF',
                  padding: '0px',
                  marginLeft: '800px',
                  fontFamily: 'Inter, sans-serif',
                  fontWeight: '600',
                }}
                onClick={copyDetails}
              >
                Copy Details
              </Button>
            </ModalHeader>
            <ModalBody>
              <RequestDetails
                onAction={onAction}
                sendDataToParent={handleDataFormChild}
                editDoctorSlots={editDoctorSlots}
                requestData={currentRequestDetails}
                setRequestDataForConfirm={setRequestDataForConfirm}
                getOfflineRequestData={getOfflineRequestData}
                showEditDoctorSlotsModal={editDoctorSlots}
                closeDetailModal={closeDetailModal}
                requestDataForConfirm={requestDataForConfirm}
              />
            </ModalBody>
          </Modal>
        </div>
        <div>
          <Modal
            centered
            isOpen={shiftReimbursementModalOpen}
            toggle={toggleShiftToReimbursementModal}
          >
            <ModalHeader
              className="revamp-modal-header"
              toggle={toggleShiftToReimbursementModal}
            >
              {`Shift to Reimbursement (Request Id: ${shiftToReimbursementReqId})`}
            </ModalHeader>
            <ModalBody
              style={{
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              {loading ? (
                <LoadingComponent />
              ) : (
                <Button
                  justifyContent="center"
                  type="submit"
                  style={{
                    backgroundColor: 'rgb(113, 79, 255)',
                    width: '40%',
                    borderColor: 'rgb(113, 79, 255)',
                    height: '44px',
                  }}
                  onClick={() =>
                    shiftAppointmentToReimbursement(shiftToReimbursementReqId)
                  }
                >
                  Confirm
                </Button>
              )}
            </ModalBody>
          </Modal>
        </div>

        <div>
          <Modal
            centered
            isOpen={requestReconfirmationModalOpen}
            toggle={toggleRequestReconfirmationModal}
          >
            <ModalHeader
              className="revamp-modal-header"
              toggle={toggleRequestReconfirmationModal}
            >
              {`Request Reconfirmation (Request Id: ${requestReconfirmationRequestId})`}
            </ModalHeader>
            <ModalBody
              style={{
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              {loading ? (
                <LoadingComponent />
              ) : (
                <Button
                  justifyContent="center"
                  type="submit"
                  style={{
                    backgroundColor: 'rgb(113, 79, 255)',
                    width: '40%',
                    borderColor: 'rgb(113, 79, 255)',
                    height: '44px',
                  }}
                  size="sm"
                  onClick={() =>
                    requestReconfirmationForConsultation(
                      requestReconfirmationRequestId
                    )
                  }
                >
                  Confirm
                </Button>
              )}
            </ModalBody>
          </Modal>
        </div>
        <div>
          <Modal
          centered
            isOpen={checkInModalOpen}
            toggle={toggleCheckInModal}
          >
            <ModalHeader
            className="revamp-modal-header"
            toggle={toggleCheckInModal}
            >
            Do you want to {opsUserStatus && opsUserStatus.opdCheckIn ? 'Check Out' : 'Check In'}?
            </ModalHeader>
            <ModalBody
            style={{
                display: 'flex',
                justifyContent: 'center',
              }}
            >
            {loading ? (
                <LoadingComponent />
              ) : (
                <Button
                  justifyContent="center"
                  type="submit"
                  style={{
                    backgroundColor: 'rgb(113, 79, 255)',
                    width: '40%',
                    borderColor: 'rgb(113, 79, 255)',
                    height: '44px',
                  }}
                  size="sm"
                  disabled={checkInOutLoader}
                 onClick={handleOpdCheckInAndCheckOut}
                >
                  {checkInOutLoader && <Spinner
                style={{ height: '16px', width: '16px', marginRight: '5px' }}
              />}
                  Confirm
                </Button>
              )}
            </ModalBody>
          </Modal>
        </div>
        <div>
          <Modal
            isOpen={showEditDoctorSlots}
            toggle={closeDoctorSlotsEditModal}
            size="lg"
            style={{ maxWidth: '80%' }}
          >
            <ModalHeader
              className="revamp-modal-header"
              toggle={closeDoctorSlotsEditModal}
            >{`Doctor Slots Edit (${getDoctorName()}-${doctorId})`}</ModalHeader>
            <ModalBody>
              <EditDoctorSlots />
            </ModalBody>
          </Modal>
        </div>
        <div>
          <Modal
            style={{
              width: '90%',
            }}
            isOpen={priceChangeRequestsModal}
            toggle={togglePriceChangeRequest}
            centered
          >
            <ModalHeader
              className="revamp-modal-header"
              toggle={togglePriceChangeRequest}
            >
              {`Price Change Request`}
            </ModalHeader>
            <ModalBody>
              {requestIdDataForPriceChange && (
                <>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'space-around',
                    }}
                  >
                    <div
                      style={{
                        fontFamily: 'Inter, sans-serif',
                        fontWeight: '500',
                        display: 'flex',
                        justifyContent: 'space-around',
                      }}
                    >
                      Doctor Id :
                      <p style={{ width: '70%' }}>
                        {requestIdDataForPriceChange?.doctorId}
                      </p>
                    </div>
                    <div
                      style={{
                        fontFamily: 'Inter, sans-serif',
                        fontWeight: '500',
                        display: 'flex',
                        justifyContent: 'space-around',
                      }}
                    >
                      Doctor Name :
                      <p style={{ width: '70%' }}>
                        {requestIdDataForPriceChange?.doctorName}
                      </p>
                    </div>
                    <div
                      style={{
                        fontFamily: 'Inter, sans-serif',
                        fontWeight: '500',
                        display: 'flex',
                        justifyContent: 'space-around',
                      }}
                    >
                      Center Id :{' '}
                      <p style={{ width: '70%' }}>
                        {requestIdDataForPriceChange?.networkCenterId}
                      </p>
                    </div>
                    <div
                      style={{
                        fontFamily: 'Inter, sans-serif',
                        fontWeight: '500',
                        display: 'flex',
                        justifyContent: 'space-around',
                      }}
                    >
                      Center Name :{' '}
                      <p style={{ width: '70%' }}>
                        {requestIdDataForPriceChange?.networkCenterName}
                      </p>
                    </div>
                    <div
                      style={{
                        fontFamily: 'Inter, sans-serif',
                        fontWeight: '500',
                        display: 'flex',
                        justifyContent: 'space-around',
                      }}
                    >
                      Previous Price :{' '}
                      <p style={{ width: '70%' }}>
                        {requestIdDataForPriceChange?.lastPrice
                          ? requestIdDataForPriceChange.lastPrice
                          : 'Not Available'}
                      </p>
                    </div>
                    <div
                      style={{
                        fontFamily: 'Inter, sans-serif',
                        fontWeight: '500',
                        display: 'flex',
                        justifyContent: 'space-around',
                      }}
                    >
                      Last Updated :{' '}
                      <p style={{ width: '70%' }}>
                        {requestIdDataForPriceChange?.priceLastUpatedDate
                          ? new Date(
                              requestIdDataForPriceChange.priceLastUpatedDate
                            ).toLocaleDateString()
                          : 'Not Available'}
                      </p>
                    </div>
                  </div>
                </>
              )}
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-around',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-around',
                  }}
                >
                  <p>Select Reason :</p>
                  <Select
                    className="price-select"
                    style={{ width: '70%', paddingBottom: '10px' }}
                    defaultValue="Select Reason"
                    onChange={(e) => setPriceChangeReason(e)}
                  >
                    <Option value="Fee got revised">Fee got revised</Option>
                    <Option value="Incorrect fee was updated in our system">
                      Incorrect fee was updated in our system
                    </Option>
                    <Option value="Incorrect price was informed by the centre while booking the appointment">
                      Incorrect price was informed by the centre while booking
                      the appointment
                    </Option>
                    <Option value="Other">Other</Option>
                  </Select>
                </div>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-around',
                  }}
                >
                  <p>New Prices:</p>
                  <InputBox
                    style={{ width: '70%' }}
                    type={'number'}
                    value={newPrice}
                    placeholder="Please enter new Prices for offline consultations"
                    onChange={(e) => setNewPrice(e.target.value)}
                  />
                </div>
              </div>
              {priceChangeReason === 'Other' && (
                <InputBox
                  type={'text'}
                  value={inputReason}
                  placeholder="Please enter reason for Price Change"
                  onChange={(e) => setInputReason(e.target.value)}
                />
              )}
              {loading ? (
                <LoadingComponent />
              ) : (
                <>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-around',
                      paddingTop: '20px',
                    }}
                  >
                    <Button
                      type="submit"
                      size="sm"
                      style={{
                        backgroundColor: '#714FFF',
                        width: '40%',
                        borderColor: '#714FFF',
                        height: '44px',
                      }}
                      onClick={() => sendPriceChangeRequest()}
                      disabled={
                        !newPrice ||
                        !priceChangeReason ||
                        (priceChangeReason === 'Other' && !inputReason)
                      }
                    >
                      Send for Approval
                    </Button>

                    <Button
                      type="submit"
                      size="sm"
                      style={{
                        backgroundColor: '#FFFFFF',
                        width: '40%',
                        borderColor: '#714FFF',
                        color: '#714FFF',
                        height: '44px',
                      }}
                      onClick={togglePriceChangeRequest}
                    >
                      Cancel
                    </Button>
                  </div>
                </>
              )}
            </ModalBody>
          </Modal>
        </div>
      </div>
    </>
  );
};

export default ReavampOfflineConsultations;
