import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import { GoogleLogin } from '@react-oauth/google';


import NavBar from '../layout/NavBar';
import { sendGoogleLogin } from '../../services/api/login';
import { setUserInfo, setAuthToken, setLabPartnerInfo } from '../../actions';
import config from '../../config';
const OuterComponent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: stretch;
  align-self: stretch;
  height: 100%;
`;
class Login extends Component {

  history = this.props.history;

  googleLogin = (credentialResponse) => {
    const dispatch = this.props.dispatch;
    return sendGoogleLogin(credentialResponse.credential)
      .then(response => {
        if (response && response.body.message === 'success') {
          dispatch(setUserInfo(response.body.result));
          dispatch(setAuthToken(response.headers.authorization));
          if (response.body.partnerInfo) {
            dispatch(setLabPartnerInfo(response.body.partnerInfo));
          }
          // return response.operatorDetails;
          return response;
        }
        throw new Error('Logged message not correct');
      })
      .then(resp => {
        const home = resp.body.result.home;
        switch (home) {
          case 'offline': {
            this.props.history.push('/revamp-offline-consults');
            break;
          }
          case 'payment': {
            this.props.history.push('/payment-requests');
            break;
          }
          case 'support': {
            this.props.history.push('/customer-support');
            break;
          }
          case 'labs-dashboard': {
            this.props.history.push('/labs-orders');
            break;
          }
          case 'reimbursement': {
            this.props.history.push('/reimburse-claims');
            break;
          }
          default:
            break;
        }
      })
      .catch(err => {
        toast.error('Incorrect username or password provided.');
      });
  };

  onError = (err) => {
    console.error('Login failed', err);
  };
  render() {
    return (
      <OuterComponent>
        <NavBar />
        <div style={{padding:'18rem', marginTop: '7rem', display: 'flex', justifyContent: 'space-around'}}>
          <GoogleLogin
            clientId={config.googleAuthConfig.clientId}
            onSuccess={this.googleLogin}
            onError={this.onError}
            scope={config.googleAuthConfig.scopes}
            prompt="consent"
            layout="horizontal"
            theme="filled_blue"
            shape="pill"
          />
        </div>
      </OuterComponent>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user,
});

export default connect(mapStateToProps)(Login);
