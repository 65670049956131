import React, { Component } from 'react';
import { connect } from 'react-redux';
import NavBar from '../layout/NavBar';
import PharmacyOrdersTable from './PharmacyOrdersTable.jsx';
import 'react-dates/initialize';
import { DateRangePicker } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import { PageHeader } from '../common/PageHeader';
import { Input } from 'valuelink/lib/tags'
import styled from 'styled-components';
import UploadRxModal from './UploadRxModal';
import AddApolloMedicineModal from './AddApolloMedicineModal.js'
import RefundOrderModal from './RefundOrderModal.js'
import UpdateApprovedAmountModal from './UpdateApprovedAmountModal.js';

const SearchBox = styled.div`
  border: 1px solid;
  border-radius: 5px;
  padding: 5px;
  display: flex;
  flex-direction: row;
`;
const SerachBoxInput = styled(Input)`
  border: 0;
  flex: 2 0 0;
  margin: 1px;
  &:focus {
    outline: none;
  }
`;
class PharmacyOrdersHome extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: null,
      endDate: null,
      startDateDelivered: null,
      endDateDelivered: null,
      focusedInputDelivered: null,
      focusedInput: null,
      focusedInputCollection: null,
      phone: null,
      isUploadPrescritionModalOpened: false,
      isUpdateApprovedAmountModalOpened: false,
      isAddApolloMedicineOpened: false,
      isShowRefundOrderModal: false,
    };
  }

  showUploadPrescriptionModal = () => {
    this.setState({ isUploadPrescritionModalOpened: !this.state.isUploadPrescritionModalOpened });
  }

  showUpdateApprovedAmountModal = () => {
    this.setState({ isUpdateApprovedAmountModalOpened: !this.state.isUpdateApprovedAmountModalOpened });
  }

    showAddApolloMedicineModal = () => {
    this.setState({ isAddApolloMedicineOpened: !this.state.isAddApolloMedicineOpened });
  }

  showRefundOrderModal = () => {
    this.setState({ isShowRefundOrderModal: !this.state.isShowRefundOrderModal });
  }
  onDatesChanged = ({ startDate, endDate }) => {
    this.setState({ startDate, endDate });
  };

  onDeliveryDatesChanged = ({ startDate, endDate }) => {
    console.log(startDate, endDate);
    this.setState({ startDateDelivered: startDate, endDateDelivered: endDate });
  };

  onPhoneSearch = (e) => {
    const phone = e.target.value.trim();
    console.log(phone);
    this.setState({ phone });
  }

  render() {
    const { startDate, endDate, startDateDelivered, endDateDelivered, focusedInput, focusedInputDelivered, phone } = this.state;
    return (
      <div style={{ width: '100%' }}>
        {this.state.isUpdateApprovedAmountModalOpened &&
          <div>
            <UpdateApprovedAmountModal onClose={this.showUpdateApprovedAmountModal} />
          </div>
        }
        { this.state.isUploadPrescritionModalOpened &&
          <div>
            <UploadRxModal onClose={this.showUploadPrescriptionModal} />
          </div>
        }
        { this.state.isAddApolloMedicineOpened &&
          <div>
            <AddApolloMedicineModal onClose={this.showAddApolloMedicineModal} />
          </div>
        }
        { this.state.isShowRefundOrderModal &&
          <div>
            <RefundOrderModal onClose={this.showRefundOrderModal} />
          </div>
        }
        <div className="container-fluid">
          <div className="row">
            <NavBar />
          </div>
          <div className="row mt-5 pt-1">
            <div className="col">
              <PageHeader>Pharmacy Orders</PageHeader>

            </div>
            <input placeholder='Search by phone no.....' style={{ width: '19rem', height: '2rem', marginTop: '2rem', borderRadius: '5px' }} onKeyDown={(e) =>
              e.key === 'Enter' ? this.onPhoneSearch(e) : null
            } />
            <div className="col-8 text-right pt-3">

              <label style={{ marginRight: '10px' }}>Booking Date:</label>
              <DateRangePicker
                displayFormat={() => 'DD/MM/YYYY'}
                startDateId="startDate"
                endDateId="endDate"
                startDate={startDate}
                endDate={endDate}
                isOutsideRange={() => false}
                minimumNights={0}
                onDatesChange={this.onDatesChanged}
                focusedInput={focusedInput}
                onFocusChange={(focusedInput) => { console.log(focusedInput); this.setState({ focusedInput }); }}
              />
            </div>
            <div className="col-8 text-right pt-3">

              <label style={{ marginRight: '10px' }}>Delivery Date:</label>
              <DateRangePicker
                displayFormat={() => 'DD/MM/YYYY'}
                startDateId="startDate"
                endDateId="endDate"
                startDate={startDateDelivered}
                endDate={endDateDelivered}
                isOutsideRange={() => false}
                minimumNights={0}
                onDatesChange={this.onDeliveryDatesChanged}
                focusedInput={focusedInputDelivered}
                onFocusChange={(focusedInputDelivered) => { console.log(focusedInputDelivered); this.setState({ focusedInputDelivered }); }}
              />
            </div>
          </div>
          <div className="row">
            <div className="col">
              <PharmacyOrdersTable startDate={startDate} endDate={endDate} startDateDelivered={startDateDelivered} endDateDelivered={endDateDelivered} showUploadPrescriptionModal={this.showUploadPrescriptionModal} showAddApolloMedicineModal={this.showAddApolloMedicineModal} phone={phone} showRefundOrderModal={this.showRefundOrderModal} showUpdateApprovedAmountModal={this.showUpdateApprovedAmountModal} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return;
}

export default connect(mapStateToProps)(PharmacyOrdersHome);

