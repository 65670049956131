import React, { useEffect, useState } from 'react';
import { getVaccinationRequestHistory } from '../../../services/api/cashless-vaccination';
import { HistoryItem, TimeContainer } from '../../common/Activities';
import { LoadingContainer, LoadingIcon } from '../../offline-consultations/table-subcomponent/StyledComponents';
import { StyledHistoryContiner } from '../styled-elements';
import { getDentalHistory } from '../../../services/api/cashless-dentals';

const HistoryContainer = ({
    requestId,
    type
}) => {

    const [history, setHistory] = useState([])
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        if (!requestId) {
            return
        }
        if(type === 'dental') {
            getDentalHistory(requestId).then((resp) => {
                console.log({resp}, '[resp]');
                if (resp && resp.message === 'success') {
                    setHistory(resp.data)
                }
            }).catch((err)=> {
                console.log(err, '[dentalHistory][err]')
            }).finally(()=>{
                setIsLoading(false)
            })
        } else {
            getVaccinationRequestHistory(requestId)
            .then((response) => {
                if (response && response.message === 'success') {
                    setHistory(response.data)
                }
            })
            .finally(() => {
                setIsLoading(false)
            })
        }
    }, [requestId])
    
    return (
        isLoading ? (
            <LoadingContainer>
                <LoadingIcon />
            </LoadingContainer>
        ) : (
            <StyledHistoryContiner>
                {(history && history.length) ? (
                    history.map(item => (
                        <HistoryItem key={item.activityId}>
                            {item.description} by {type === 'dental' ? (item.userName || item.userType) : item.userName}  
                            {item.meta || ''} 
                            {item.audioFileLink ? 
                                <a href={item.audioFileLink} target="_blank" rel="noopener noreferrer"> (Link)</a> : 
                            <></>}
                            <TimeContainer>{item.eventTime}</TimeContainer>
                        </HistoryItem>
                    ))
                ) : (<HistoryItem>No Activities here...</HistoryItem>)}
            </StyledHistoryContiner>
        )
    )
}

export default HistoryContainer