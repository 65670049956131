import React, { useEffect, useMemo, useState } from 'react'
import NavBar from '../layout/NavBar'
import ReactTable from 'react-table'
import { getAgentActivity } from '../../services/api/offline-consult'
import { withRouter } from 'react-router-dom';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { Modal, ModalBody, ModalFooter, ModalHeader, Button } from 'reactstrap';
import LoadingComponent from '../common/LoadingComponent';
import Select from 'react-select';
import { toast } from 'react-toastify';
import {
    fetchOpsUsers,
    // getAllOfflineRequests,
    updateGroupId
} from '../../services/api/offline-consult'
import DownloadCsv from './DownloadCSV';
import { useSelector } from 'react-redux';

const AgentActivityTable = () => {
    const [dataSource, setDataSource] = useState([])
    const [loading, setLoading] = useState(false);
    const [assigneData, setAssigneData] = useState([]);
    const [opsUserData, setOpsUserData] = useState([]);
    const [selectedGroupId, setSelectedGroupId] = useState(null);
    const [selectedAssigne, setSelectedAssigne] = useState(null);
    const [selectedOptions, setSelectedOptions] = useState();
    const [isGropIdModalOpen, setIsGropIdModalOpen] = useState(false);
    const [removingGroupId, setRemovingGroupId] = useState(false);

    const {
        user
      } = useSelector((state) => state);

    const getAllAgentActivity = async () => {
        try {
            const response = await getAgentActivity()
            console.log(response, 'vsxbcczs');
            setDataSource(response.result)
        } catch (error) {
            console.error({ error }, 'Error fetching agent activity')
        }
    }

    const openGroupIdModal = () => {
        setIsGropIdModalOpen(true);
    }

    const handleRemoveGroupId = () => {
        setRemovingGroupId(true);
        setIsGropIdModalOpen(true);
    }

    const closeGroupIdModal = () => {
        if(removingGroupId){
            setRemovingGroupId(false)
        }
        setIsGropIdModalOpen(false);
    }

    const updateGroupIdData = async () => {
        if(removingGroupId){
            if(selectedAssigne){
                const body = {
                    opsUserId: selectedAssigne.value,
                    removingGroup : true
                }
                setLoading(true)
                const response = await updateGroupId(body);
                if (response) {
                    toast.success('Group ID Removed successfully');
                    getAllAgentActivity();
                    closeGroupIdModal();
                    setLoading(false)
                } else {
                    toast.error('Failed to update Group ID');
                    setLoading(false)
                }
            }else{
                toast.error('Please select user to remove from the group!');
            }
        }else if (selectedAssigne && selectedGroupId) {
            const body = {
                opsUserId: selectedAssigne.value,
                // opsUserName: selectedAssigne.opsUserName,
                groupId: selectedGroupId.value
            }
            setLoading(true)
            const response = await updateGroupId(body);
            if (response) {
                toast.success('Group ID updated successfully');
                getAllAgentActivity();
                closeGroupIdModal();
                setLoading(false)
            } else {
                toast.error('Failed to update Group ID');
            }
        } else {
            toast.error('Please select both user and group ID');
        }
    }

    const groupIdOptions = [
        { value: '1', label: '1' },
        { value: '2', label: '2' },
        { value: '3', label: '3' },
    ]

    const fetchOpsUsersData = async () => {
        const data = await fetchOpsUsers();
        const allOpsUsers = data.map((user) => {
            return {
                value: user.opsUserId,
                label: user.opsUserName,
                groupId:user.groupId
            };
        });
        setOpsUserData(allOpsUsers);
        setAssigneData(allOpsUsers);
    };

    useEffect(() => {
        fetchOpsUsersData();
    }, [removingGroupId]);

    useEffect(()=>{
        if(removingGroupId){
            const allOpsUsers = opsUserData.filter(ele => ele.groupId).map((user) => {
                return {
                    value: user.value,
                    label: user.label + " Group ID : " + user.groupId,
                    groupId:user.groupId
                };
            });
            setAssigneData(allOpsUsers);
        }else{
            const allOpsUsers = opsUserData.map((user) => {
                return {
                    value: user.value,
                    label: user.label,
                    groupId:user.groupId
                };
            });
            setAssigneData(allOpsUsers);
        }
    },[removingGroupId, opsUserData])

    const columns = useMemo(
        () => [
            {
                Header: 'Name',
                accessor: 'name',
                width: 100,
                style: {
                    textAlign: 'center',
                    fontWeight: '600',
                    alignContent: 'center',
                },
                filterable: true,
            },
            {
                Header: 'Group ID',
                accessor: 'groupId',
                filterable: true,
                width: 100,
                style: {
                    textAlign: 'center',
                    fontWeight: '600',
                    alignContent: 'center',
                },
            },
            {
                Header: 'Current Status',
                accessor: 'currentStatus',
                width: 200,
                style: {
                    textAlign: 'center',
                    fontWeight: '600',
                    alignContent: 'center',
                },
            },
            {
                Header: 'Pending Consultations',
                accessor: 'totalPendingConsultations',
                width: 200,
                style: {
                    textAlign: 'center',
                    fontWeight: '600',
                    alignContent: 'center',
                },
            },
            {
                Header: 'Last Check In',
                accessor: 'lastCheckIn',
                width: 200,
                style: {
                    textAlign: 'center',
                    fontWeight: '600',
                    alignContent: 'center',
                },
            },
            {
                Header: 'Last Check Out',
                accessor: 'lastCheckOut',
                width: 200,
                style: {
                    textAlign: 'center',
                    fontWeight: '600',
                    alignContent: 'center',
                },
            },
            {
                Header: 'Total Check Ins',
                accessor: 'checkInCount',
                width: 200,
                style: {
                    textAlign: 'center',
                    fontWeight: '600',
                    alignContent: 'center',
                },
            }
        ],
        []
    )

    const tableStyle = {
        border: 'none',
        'font-size': '15px',
        // overflow: 'hidden',
        'background-color': '#FFFFFF',
        'font-weight': '500',
        'font-family': 'Montserrat',
        // width: '100%',
        padding: '10px',
    };

    useEffect(() => {
        getAllAgentActivity();
    }, []);

    return (
        <div style={{
            backgroundColor: '#F2F1F6',
            width: '100%',
        }}>
            <div>
                <NavBar />
            </div>
            <div style={{
                display: 'flex',
                justifyContent: 'space-between',
                flexDirection: 'column',
                marginTop: '72px',
                marginLeft: '20px',
            }} >
                <div>
                    <div style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        flexDirection: 'row',
                    }}>
                        <div style={{
                            marginLeft: '60px',
                        }}>
                            <h1>OPS Team Activity</h1>
                        </div>
                        <div style={{
                            marginRight: '60px',
                            gap: '10px',
                            display: 'flex',
                        }}>
                        {user && user.canAssignGroup ? <><button style={{
                                background: '#714FFF',
                                borderColor: '#714FFF',
                                margin: '0px',
                                borderRadius: '5px',
                                border: '0px',
                                color: 'white',
                                // height: '48px',
                                padding: '12px 12px',
                                fontSize: '14px',
                                fontWeight: '600',
                                fontFamily: 'Inter, sans-serif',
                                justifyContent: 'flex-start',
                            }}
                                onClick={openGroupIdModal}
                            >
                                Add/Update Group ID
                            </button>
                            <button style={{
                                background: '#714FFF',
                                borderColor: '#714FFF',
                                margin: '0px',
                                borderRadius: '5px',
                                border: '0px',
                                color: 'white',
                                // height: '48px',
                                padding: '12px 12px',
                                fontSize: '14px',
                                fontWeight: '600',
                                fontFamily: 'Inter, sans-serif',
                                justifyContent: 'flex-start',
                            }}
                                onClick={handleRemoveGroupId}
                            >
                                Remove Group ID
                            </button></> : <></>}
                            <DownloadCsv />
                        </div>
                    </div>
                    <div style={{
                        padding: '50px',
                    }}>
                        <ReactTable style={tableStyle} showPaginationTop data={dataSource} columns={columns} />
                    </div>
                    <div>
                        <Modal
                            style={{
                                width: '90%',
                            }}
                            isOpen={isGropIdModalOpen}
                            toggle={closeGroupIdModal}
                            centered
                        >
                            <ModalHeader
                                className="revamp-modal-header"
                                toggle={closeGroupIdModal}
                            >
                                {removingGroupId ? "Remove GroupId" : 
                                "Add/Update Group ID"}
                            </ModalHeader>
                            <ModalBody>
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'space-between',
                                    }}
                                >
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                        }}
                                    >
                                        <label>Select Assigne:</label>
                                        <Select
                                            className="assigne-select"
                                            options={assigneData}
                                            placeholder="Select user"
                                            value={selectedOptions}
                                            onChange={selectedOption => setSelectedAssigne(selectedOption)}
                                            isSearchable={true}
                                        />
                                    </div>
                                    {!removingGroupId && <div style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                    }}
                                        className='mt-2'>
                                        <label>Group ID:</label>
                                        <Select
                                            type="text"
                                            placeholder="Select Group ID"
                                            className="assigne-select"
                                            options={groupIdOptions}
                                            onChange={selectedOption => setSelectedGroupId(selectedOption)}
                                        />
                                    </div>}
                                </div>

                                    <>
                                        <div
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'space-around',
                                                paddingTop: '20px',
                                            }}
                                        >
                                            <Button
                                                type="submit"
                                                size="sm"
                                                style={{
                                                    backgroundColor: '#714FFF',
                                                    width: '40%',
                                                    borderColor: '#714FFF',
                                                    height: '44px',
                                                }}
                                                onClick={updateGroupIdData}
                                            disabled={
                                                loading
                                            }
                                            >
                                                Submit
                                            </Button>

                                            <Button
                                                type="submit"
                                                size="sm"
                                                style={{
                                                    backgroundColor: '#FFFFFF',
                                                    width: '40%',
                                                    borderColor: '#714FFF',
                                                    color: '#714FFF',
                                                    height: '44px',
                                                }}
                                                onClick={closeGroupIdModal}
                                            >
                                                Cancel
                                            </Button>
                                        </div>
                                    </>
                            </ModalBody>
                        </Modal>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default AgentActivityTable