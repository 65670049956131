import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';

import { getNotificationPanelData } from '../../../services/api/notification-panel';

import { showRequestModal, showInternalComments } from '../../../actions/index';

const Notification = styled.div`
  background: white;
  color: black;
  border-radius: 0.3rem;
  padding: 0.2rem;
  margin: 1rem 0.5rem;
  cursor: pointer;
`;

const Container = styled.div`
  position: absolute;
  max-width: 25rem;
  max-height: 30rem;
  min-width: 25rem;
  min-height: 30rem;
  background-color: #e6e6e6;
  color: black;
  border-radius: 0.3rem;
  padding: 1rem;
  right: 1.5rem;
`;

const TopContainer = styled.div``;

const UserName = styled.em`
  color: #3a3a3b;
  float: left;
`;

const CommentDateTime = styled.em`
  float: right;
  color: #3a3a3b;
`;

const CommentBody = styled.div`
  font-size: 0.9rem;
  text-align: justify;
  display: inline-block;
  width: 100%;
`;

const NotificationTop = styled.div`
  min-height: 1rem;
  font-size: 0.8rem;
`;

const BodyContainer = styled.div`
  overflow: auto;
  max-height: 26rem;
`;

class NotificationContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      notifications: [],
    };

    this.wrapperRef = React.createRef();
  }

  componentWillMount() {
    this.fetchAndSetNotifications();
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  fetchAndSetNotifications = () => {
    return getNotificationPanelData().then((data) => {
      console.log({ data }, '[fetchAndSetNotifications]');
      if (data && Array.isArray(data)) {
        this.setState({
          notifications: data,
        });
      }
    });
  };

  handleClickOutside = (event) => {
    if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
      this.props.close();
    }
  };

  handleNotificationClick = (requestId, commentType) => {
    if (commentType === 'internal') {
      this.props.dispatch(showInternalComments(requestId));
      this.props.history.push('/revamp-offline-consults');
      return;
    }
    this.props.dispatch(showRequestModal(requestId));
    this.props.history.push('/revamp-offline-consults');
  };

  render() {
    const { notifications } = this.state;
    return (
      <Container
        style={{
          backgroundColor: 'white',
          boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.1)',
        }}
        ref={this.wrapperRef}
      >
        <TopContainer
          style={{
            borderBottom: '1px solid #EBEAF5',
          }}
        >
          <label
            style={{
              fontWeight: 'bold',
              fontFamily: 'Arial, sans-serif',
              color: '#302E6B',
            }}
          >
            {' '}
            Notifications
          </label>
          <label
            style={{
              marginLeft: '0.5rem',
              color: '#A5A6BB',
              fontFamily: 'Arial, sans-serif',
              fontSize: '0.7rem',
            }}
          >
            {notifications.length || 0} New
          </label>
        </TopContainer>
        <BodyContainer>
          {notifications &&
            notifications.map((notification) => (
              <Notification
                key={notification.commentId}
                onClick={() =>
                  this.handleNotificationClick(
                    notification.requestId,
                    notification.commentType
                  )
                }
                style={{
                  borderBottom: '2px solid #EBEAF5',
                }}
              >
                <NotificationTop>
                  <UserName>
                    {notification.commentType === 'network' ? 'Network' : ''}{' '}
                    {notification.userName}
                  </UserName>
                  <CommentDateTime>{notification.createdAt}</CommentDateTime>
                </NotificationTop>
                <CommentBody>{notification.content}</CommentBody>
              </Notification>
            ))}
        </BodyContainer>
      </Container>
    );
  }
}

export default connect()(withRouter(NotificationContainer));
