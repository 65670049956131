import React, { Component } from 'react';
import {
  OuterComponent,
  PageTitle,
  CloseModal,
} from '../common/modal/StyledComponent';
import {
  fetchUsersRelative,
} from '../../services/api/offline-consult';
import {
  getUserData,
  createUser,
  bookOnlineSpecialistConsult,
} from '../../services/api/online-consultation-booking';
import StyledTappable from '../common/StyledTappable';
import DependentModal from '../common/modal/dependentModal';
import Link from 'valuelink';
import { toast } from 'react-toastify';
import PlaceConsultRequestForm from './PlaceConsultRequestForm';
import CreateUserForm from './CreateUserForm';
import Modal from 'react-modal';

const TabActiveStyle = {
  backgroundColor: 'grey',
  color: 'white',
};
const TabInactiveStyle = {
  backgroundColor: 'white',
  color: 'black',
};
const emailRe =
  /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

export default class BookConsultationModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      addDependentModalOpen: false,
      canAddDependents: false,
      // possible values: 'existing users' or 'new users'
      bookingConsultationsFor: 'existing-user',
      loading: false,
      // verticalLoading: false,
      // verticalsResult: [],
      relativeResult: [],
      relativeLoading: false,
      phone: '',
      userName: '',
      userId: '',
      // doctorId: '',
    };
  }

  componentDidMount() {
    // this.fetchVerticals();
    // this.getSponsors();
  }

  afterOpenModal = () => {
    console.log('Opened Book Consultation Modal');
  };

  closeModal = () => {
    this.setState({
      userId: '',
      userName: '',
      relativeResult: [],
      phone: '',
    });
    this.props.closeModal();
  };

  // Add Dependent Modal
  openAddDependentModal = () => {
    this.setState({
      addDependentModalOpen: true,
    });
  };

  closeAddDependent = () => {
    this.setState(
      {
        addDependentModalOpen: false,
      },
      this.afterOpenModal
    );
  };

  onUserIdInputBlur = (val) => {
    console.log(val, '[userId]');
    this.setState({
      relativeLoading: true,
    });
    fetchUsersRelative(val)
      .then((response) => {
        const relatives = [
          {
            value: -1,
            label: 'Self',
          },
        ].concat(
          response.data.map((ele) => ({
            label: `${ele.relativeName}(${ele.relationName})`,
            value: ele.relativeId,
          }))
        );
        this.setState({
          relativeResult: relatives,
        });
      })
      .finally(() => {
        this.setState({ relativeLoading: false });
      });
  };
  validateUserForm = (userForm) => {
    let errors = [];
    if (!userForm.name) errors.push('Name');
    if (!emailRe.test(userForm.email))
      errors.push('Valid Email');
    if (!userForm.dob) errors.push('DOB');
    if (!userForm.gender) errors.push('Gender');
    if (!userForm.phone || userForm.phone.length < 10)
      errors.push('Valid Phone No.');

    if(errors.length > 0)
      toast.error(`The following feilds are required: ${errors.join(',')}`);
    return errors.length === 0;
  };
  createNewUser = (userForm) => {
    console.log(' [new user form in book consult]', userForm);
    if (this.validateUserForm(userForm)) {
      createUser(userForm).then((response) => {
        if(response.message === 'serverError'){
          throw new Error(response.errorMessage);
        }
        const userDetails = response.data;
        console.log('user details for created user', userDetails);
        this.setState({
          bookingConsultationsFor: 'existing-user',
          phone: userDetails.phone,
          userId: userDetails.userId,
          userName: userDetails.name,
        });
        this.onUserIdInputBlur(`${this.state.userId}-${this.state.userName}`);
        toast.success('User created successfully');
      }).catch(err =>{
        toast.error(err.message);
      });
    }
  };

  placeSpecialistConsultRequest = (data) => {
    console.log(
      '[data from PlaceSpecialistConsultRequestForm',
      data
    );
    const { doctorId, relativeId, prescriptionSms, requestedCallType, consultationLanguage, consultationPayType } = data;
    const userId = this.state.userId;
    console.log(data);
    if (doctorId && userId) {
      this.setState({ loading: true });
      bookOnlineSpecialistConsult({
        doctorId,
        userId,
        relativeId: relativeId !== -1 ? relativeId : null,
        prescriptionSms,
        requestedCallType,
        consultationLanguage
      }, consultationPayType)
        .then((response) => {
          if (response.message === 'serverError') {
            throw new Error(response.errorMessage);
          }
          toast.success(`Successfully Booked Consultation`);
          this.closeModal();
        })
        .catch((err) => {
          toast.error(err.message);
        })
        .finally(() => this.setState({ loading: false }))
    } else {
      toast.error('Please enter all required details');
    }
  };

  getUserDetails = (value) => {
    this.setState({
      phone: value,
    });
    if (value.length === 10) {
      getUserData(value)
        .then((res) => {
          console.log(res, '[res]');
          if (res.message === 'success') {
            this.setState({
              userId: res.data[0].userId,
              userName: res.data[0].userName,
              canAddDependents: res.data[0].policyId === null,
            });
            this.onUserIdInputBlur(
              `${this.state.userId}-${this.state.userName}`
            );
          } else {
            toast.error(res.errorMessage);
          }
        })
        .catch((err) => {
          console.log(err, '[user-err]');
        });
    }
  };

  render() {
    const userIdLink = Link.state(this, 'userId').onChange(() => {
      this.onUserIdInputBlur();
    });
    console.log(this.props, this.state, 'render');

    return (
      <Modal
        isOpen={this.props.modalIsOpen}
        onAfterOpen={this.afterOpenModal}
        onRequestClose={this.props.closeModal}
      >
        <OuterComponent>
          <React.Fragment>
            <PageTitle>
              Book Online Consultation
              <StyledTappable onTap={this.closeModal}>
                <CloseModal />
              </StyledTappable>
            </PageTitle>
          </React.Fragment>
          <div className="flex flex-row">
            <StyledTappable
              onTap={() => {
                this.setState({ bookingConsultationsFor: 'existing-user' });
              }}
            >
              <div
                className="flex flex-col m-4 p-2"
                style={
                  this.state.bookingConsultationsFor === 'existing-user'
                    ? TabActiveStyle
                    : TabInactiveStyle
                }
              >
                Book Consultation for Existing User
              </div>
            </StyledTappable>
            <StyledTappable
              onTap={() => {
                this.setState({ bookingConsultationsFor: 'new-user' });
              }}
            >
              <div
                className="flex flex-col m-4 p-2"
                style={
                  this.state.bookingConsultationsFor === 'new-user'
                    ? TabActiveStyle
                    : TabInactiveStyle
                }
              >
                Create New User
              </div>
            </StyledTappable>
          </div>
          {this.state.bookingConsultationsFor === 'existing-user' ? (
            <PlaceConsultRequestForm
              loading={this.state.loading}
              placeSpecialistConsultRequest={this.placeSpecialistConsultRequest}
              closeModal={this.closeModal}
              onUserIdInputBlur={this.onUserIdInputBlur}
              relativeResult={this.state.relativeResult}
              relativeLoading={this.state.relativeLoading}
              phone={this.state.phone}
              getUserDetails={this.getUserDetails}
              userId={this.state.userId}
              userName={this.state.userName}
              canAddDependents={this.state.canAddDependents}
              addDependentModalOpen={()=> this.setState({ addDependentModalOpen: true}) }
            />
          ) : (
            <CreateUserForm
              loading={this.state.loading}
              createNewUser={this.createNewUser}
              closeModal={this.closeModal}
            />
          )}
          {this.state.addDependentModalOpen && (
            <DependentModal
              openModal={() => this.setState({ addDependentModalOpen: true })}
              userPhone={this.state.phone}
              modalIsOpen={this.state.addDependentModalOpen}
              closeModal={() => {
                this.setState({ addDependentModalOpen: false });
                if (this.state.userId && this.state.userName)
                  this.onUserIdInputBlur(
                    `${this.state.userId}-${this.state.userName}`
                  );
              }}
            />
          )}
        </OuterComponent>
      </Modal>
    );
  }
}
