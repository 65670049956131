import axios from 'axios';
import jsonpipe from 'jsonpipe';

import { store } from '../../store';
import config from '../../config';
import { Panorama } from 'aws-sdk';

const redirectForLogin = () => {
 window.location.href = '/';
}

axios.interceptors.response.use((response) => response,  (error) => {
  const statusCode = error.response.data.code;
  console.log(statusCode);
  return statusCode === 401 ?  redirectForLogin() : Promise.reject(error);
});

const baseUrl = config.baseUrl;

export default function baseRequest(url, method, params, data) {
  const headers = {};
  const state = store.getState();
  if (state.user.token) {
    headers.authorization = state.user.token;
  }
  const source = axios.CancelToken.source();
  console.log('Data: ', data);
  const request = axios({
    url: `${baseUrl}${url}`,
    method,
    headers,
    params,
    data,
    cancelToken: source.token, // Pass cancelToken for cancellation
  })
    .then((response) => {
      console.log('response after request', response);
      if (response) {
        return response.data;
      }
      throw new Error('Status code is not okk');
    })
    .catch((err) => {
      if (axios.isCancel(err)) {
        console.log('Request canceled:', err.message);
      } else {
        console.error({ err, response: err.response }, 'Error in endpoint');
      }
      throw err;
    });

      // Attach the cancel function to the request object for manual cancellation if needed
    request.cancel = () => {
      source.cancel("Request canceled by the user.");
    };

    return request;
}

export function baseRequestMultiPart(url, method, params, data) {
  const headers = { 'Content-Type': 'multipart/form-data' };
  const state = store.getState();
  if (state.user.token) {
    headers.authorization = state.user.token;
  }
  const options = {
    url: `${baseUrl}${url}`,
    method,
    headers,
    params,
    data,
  };
  console.log(options);
  return axios(options)
    .then((response) => {
      console.log('response after request', response);
      if (response) {
        return response.data;
      }
      throw new Error('Status code is not okk');
    })
    .catch((err) => {
      console.error({ err, response: err.response }, 'Error in endpoint');
      throw err;
    });
}

export function baseRequestWithAxiosResponse(url, method, params, data) {
  const headers = {};
  const state = store.getState();
  if (state.user.token) {
    headers.authorization = state.user.token;
  }
  return axios({
    url: `${baseUrl}${url}`,
    method,
    headers,
    params,
    data,
  })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      console.error({ err }, 'Error in endpoint');
      throw err;
    });
}

export function baseRequestWithHeaders(
  url,
  method,
  params,
  data,
  onUploadProgress,
  onResponseReceived,
  onResponseComplete,
  onResponseError
) {
  const headers = {};
  const state = store.getState();
  if (state.user.token) {
    headers.authorization = state.user.token;
  }
  return axios({
    url: `${baseUrl}${url}`,
    method,
    headers,
    params,
    data,
    onUploadProgress,
  })
    .then((response) => {
      if (response && response.status === 200) {
        return {
          body: response.data,
          headers: response.headers,
        };
      }
      throw new Error('Status code is not okk');
    })
    .catch((err) => {
      console.error({ err }, 'Error in endpoint');
      throw err;
    });
}

export function jsonPipeRequest(
  url,
  method,
  params,
  data,
  onUploadProgress,
  onResponseReceived,
  onResponseComplete,
  onResponseError
) {
  const headers = {};
  const state = store.getState();
  if (state.user.token) {
    headers.authorization = state.user.token;
    headers['content-type'] = 'application/json';
  }
  jsonpipe.flow(`${baseUrl}${url}`, {
    delimiter: '\r\n',
    success: onResponseReceived,
    complete: onResponseComplete,
    error: onResponseError,
    method: method,
    headers,
    data: JSON.stringify(data),
    disableContentType: true,
  });
}

export function baseRequestWithHeadersFileResponse(
  url,
  method,
  params,
  data,
  onUploadProgress
) {
  const headers = {};
  const state = store.getState();
  if (state.user.token) {
    headers.authorization = state.user.token;
  }

  window.open(`${baseUrl}${url}&auth=${headers.authorization.split(' ')[1]}`);
  return Promise.resolve();
}

export function checkSuccessfulResponse(response) {
  if (response.message === 'success') {
    return response.data;
  }
  throw new Error('Status Message Not Correct');
}

export function baseRequestWithUserHeaders(url, method, params, data, token) {
  const headers = {};
  const state = store.getState();
  headers.authorization = token;
  return axios({
    url: `${baseUrl}${url}`,
    method,
    headers,
    params,
    data,
  })
    .then((response) => {
      if (response && response.status === 200) {
        return {
          body: response.data,
          headers: response.headers,
        };
      }
      throw new Error('Status code is not okk');
    })
    .catch((err) => {
      console.error({ err }, 'Error in endpoint');
      throw err;
    });
}

export function baseRequestWithOptions(url, method, data, options) {
  const headers = {};
  const state = store.getState();
  if (state.user.token) {
    headers.authorization = state.user.token;
  }
  let updatedOptions;
  if (options) {
    updatedOptions = Object.assign({}, options);
    updatedOptions.headers = {
      ...headers,
      ...options.headers,
    };
  }
  return axios({
    url: `${baseUrl}${url}`,
    method,
    data,
    ...updatedOptions,
  })
    .then((response) => {
      if (response) {
        return response.data;
      }
      throw new Error('Status code is not okk');
    })
    .catch((err) => {
      console.error({ err, response: err.response }, 'Error in endpoint');
      throw err;
    });
}

export const getAddedDoctorRequestList = () => {
  const url = '/new-ops/added-doctor-request-list';
  const method = 'GET';
  return baseRequest(url, method, null,null);
  };
